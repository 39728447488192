import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, CssBaseline, Grid, ThemeProvider } from "@mui/material";
import { AllInbox, Coffee, History, People, ShoppingCart } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { coffeeShopTheme } from "../../themes";
import UserWrapper from "../../wrappers/user.wrapper";
import { CheckIfUserHasPermission } from "../../utils/access";
import { COFFEE_SHOP_MANAGEMENT_ALLOWED_ACCESSES } from "../../constants/access.constant";
import CashierWrapper from "../../wrappers/cashier.wrapper";
import GenericDialog from "../../components/Dialogs/Generic";
import CashierForm from "../../components/Forms/CashierForm";


export default function CoffeeShop() {
    const [showCashierDialog, setShowCashierDialog] = useState(false);
    const user = UserWrapper.get();
    const cashier = CashierWrapper.get();
    const navigate = useNavigate();
    const isCashierOpen = cashier && !!cashier.OpenedAt;
    const isUserAllowedToManage = CheckIfUserHasPermission(
        user.access!,
        COFFEE_SHOP_MANAGEMENT_ALLOWED_ACCESSES,
    );

    const toggleCashierDialog = () => setShowCashierDialog(!showCashierDialog);
    const handleProductsClick = (e: React.MouseEvent<HTMLElement>) => navigate("/coffee-shop/products");
    const handleStaffClick = (e: React.MouseEvent<HTMLElement>) => navigate("/coffee-shop/staff");
    const handleSaleHistoryClick = (e: React.MouseEvent<HTMLElement>) => navigate("/coffee-shop/sales");
    const handleCashierClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!isCashierOpen) toggleCashierDialog();
        else navigate("/coffee-shop/cashier");
    };

    return (
        <ThemeProvider theme={coffeeShopTheme}>
            <CssBaseline />
            <Helmet title="Admin ONE | Coffee Shop" />
            <GenericDialog
                onClose={toggleCashierDialog}
                open={showCashierDialog}
                title="Abrir Caixa"
            >
                <CashierForm onClose={toggleCashierDialog} />
            </GenericDialog>
            <div style={{ textAlign: "center" }}>
                <Grid
                    container
                    sx={{ marginTop: "4rem" }}
                >
                    <Grid item xs={12} sx={{ color: "white" }}>
                        <h3>Bem vindo(a) a tela exclusiva para a cafeteria <Coffee sx={{ height: 20 }} /></h3>
                    </Grid>
                    <Grid item xs={12} sx={{ color: "white" }}>
                        <h4>Navegue usando as opções abaixo!</h4>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            color: "white",
                            display: "flex",
                            gap: "2rem",
                            marginTop: "4rem",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            startIcon={<ShoppingCart />}
                            variant="contained"
                            color="secondary"
                            onClick={handleCashierClick}
                        >
                            {isCashierOpen ? 'Caixa' : 'Abrir Caixa'}
                        </Button>
                        <Button
                            startIcon={<People />}
                            variant="contained"
                            color="secondary"
                            onClick={handleStaffClick}
                        >
                            Staff
                        </Button>
                        <Button
                            startIcon={<AllInbox />}
                            variant="contained"
                            color="secondary"
                            onClick={handleProductsClick}
                        >
                            Produtos
                        </Button>
                        {isUserAllowedToManage &&
                            <Button
                                startIcon={<History />}
                                variant="contained"
                                color="secondary"
                                onClick={handleSaleHistoryClick}
                            >
                                Histórico de Vendas
                            </Button>
                        }
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}
