import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQueryClient } from "@tanstack/react-query";
import { GridColDef } from "@mui/x-data-grid";
import { AddBox, DeleteForever as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { Button, CssBaseline, IconButton, TextField, ThemeProvider, Tooltip } from "@mui/material";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import { useProducts } from "../../hooks";
import { CheckIfUserHasPermission } from "../../utils/access";
import UserWrapper from "../../wrappers/user.wrapper";
import { PRODUCTS_ACTIONS_ALLOWED_ACCESSES } from "../../constants/access.constant";
import AODataTable from "../../components/Table/DataTable";
import { PromiseToast, SuccessToast } from "../../utils/toaster";
import GenericDialog from "../../components/Dialogs/Generic";
import * as ProductService from "../../services/product.service";
import { coffeeShopTheme, defaultTheme } from "../../themes";
import ProductForm from "../../components/Forms/ProductForm";
import { Product } from "../../types/product.type";
import { coffeeShopDepartmentId } from "../../config";
import "./Products.styles.scss";


export default function Products() {
    const [isEditing, setIsEditing] = useState(false);
    const [showProductFormDialog, setShowProductDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [productToDelete, setProductToDelete] = useState<Product>();
    const [productToEdit, setProductToEdit] = useState<Product>();
    const [isDeleteInputTxtValid, setIsDeleteInputTxtValid] = useState(false);
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const { loadingProducts, products } = useProducts();
    const queryClient = useQueryClient();
    const location = useLocation();
    const isCoffeeShop = location.pathname.includes("/coffee-shop");
    const consideredProducts = isCoffeeShop ? products?.filter((p) => p.DepartmentId === coffeeShopDepartmentId) : products;
    const isUserAllowedToAct = CheckIfUserHasPermission(
        user.access!,
        PRODUCTS_ACTIONS_ALLOWED_ACCESSES
    );

    const columns: GridColDef<Product>[] = [
        { field: "Name", headerName: "Nome", minWidth: 300, maxWidth: 350, align: "center", headerAlign: "center" },
        {
            field: "Department",
            headerName: "Departamento",
            minWidth: 150,
            maxWidth: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Department?.Name,
        },
        {
            field: "Category",
            headerName: "Categoria",
            minWidth: 120,
            maxWidth: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Category?.Name,
        },
        {
            field: "SalePrice",
            headerName: "Preço para venda",
            minWidth: 200,
            maxWidth: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `R$${Number(params.row.SalePrice).toFixed(2)}`,
        },
        {
            field: "CreatedDate",
            headerName: "Data de criação",
            minWidth: 200,
            maxWidth: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => new Date(params.row.CreatedDate).toLocaleString(),
        },
        {
            field: "UpdatedDate",
            headerName: "Última atualização",
            minWidth: 200,
            maxWidth: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.UpdatedDate ? new Date(params.row.UpdatedDate).toLocaleString() : "-",
        },
        {
            field: "Actions",
            headerName: "Ações",
            width: 150,
            align: "center",
            headerAlign: "center",
            colSpan: 4,
            renderCell: (params) => (
                <span>
                    <Tooltip
                        title={!isUserAllowedToAct ? "Você não tem permissão pra fazer isso" : "Editar"}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToAct} onClick={(e) => handleEditButtonClick(params.row)}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={!isUserAllowedToAct ? "Você não tem permissão pra fazer isso" : "Deletar"}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToAct} onClick={(e) => { toggleDeleteDialog(params.row) }}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </span>
            ),
        },
    ];

    const toggleProductFormDialog = (submitted?: boolean) => {
        if (typeof submitted === "boolean" && submitted) {
            queryClient.invalidateQueries(['products']).then(() => {
                SuccessToast(`Produto ${isEditing ? 'atualizado' : 'criado'}!`);
            });
        }
        setShowProductDialog(!showProductFormDialog);
    };
    const toggleDeleteDialog = (clickedProduct?: Product) => {
        if (clickedProduct) setProductToDelete(clickedProduct);
        setShowDeleteDialog(!showDeleteDialog);
    };
    const handleCreateButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setIsEditing(false);
        toggleProductFormDialog();
    };
    const handleEditButtonClick = (u: Product) => {
        setIsEditing(true);
        setProductToEdit(u);
        toggleProductFormDialog();
    };
    const deleteProduct = async () => {
        if (productToDelete) {
            toggleDeleteDialog();
            await PromiseToast(
                ProductService.DeleteProduct(productToDelete.Id, cookies.session_token),
                {
                    error: "Não conseguimos deletar o produto. Por favor tente novamente!",
                    pending: "Deletando produto...",
                    success: "Produto deletado!",
                }
            );
            await queryClient.invalidateQueries(['products']);
        }
    };
    const validateDeleteInputTxt = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsDeleteInputTxtValid(e.target.value === productToDelete?.Name);
    };

    return (
        <ThemeProvider theme={isCoffeeShop ? coffeeShopTheme : defaultTheme}>
            <CssBaseline />
            <Helmet title="Admin ONE | Produtos" />
            <GenericDialog
                onClose={toggleDeleteDialog}
                open={showDeleteDialog}
                title="Deletar Produto"
                useConfirmAndCancelButtons
                onConfirm={deleteProduct}
                disableConfirm={!isDeleteInputTxtValid}
            >
                <span style={{ lineHeight: "3rem" }}>
                    <p style={{ fontSize: "1.2rem" }}>Tem certeza que deseja deletar esse produto?</p>
                    <p style={{ fontSize: "1.2rem" }}>Para confirmar digite <strong>{productToDelete?.Name}</strong></p>
                    <TextField fullWidth onChange={validateDeleteInputTxt} size="small" />
                </span>
            </GenericDialog>
            <GenericDialog
                onClose={toggleProductFormDialog}
                open={showProductFormDialog}
                title={`${isEditing ? 'Editar' : 'Criar'} Produto`}
            >
                <ProductForm
                    product={productToEdit}
                    onClose={toggleProductFormDialog}
                    isEditing={isEditing}
                    isCoffeeShop={isCoffeeShop}
                />
            </GenericDialog>
            <AODataTable
                useDefaultPreComponent
                columns={columns}
                rows={consideredProducts!}
                isLoading={loadingProducts}
                title="Lista de Produtos"
                preComponentAction={
                    <Tooltip
                        title={!isUserAllowedToAct && "Você não tem permissão pra fazer isso"}
                        disableHoverListener={isUserAllowedToAct}
                        arrow
                    >
                        <span>
                            <Button
                                startIcon={<AddBox />}
                                variant="contained"
                                sx={{ minWidth: "14rem", maxWidth: "16rem" }}
                                disabled={!isUserAllowedToAct || loadingProducts}
                                classes={{ disabled: "create-product-btn-disabled" }}
                                onClick={handleCreateButtonClick}
                            >
                                Criar Produto
                            </Button>
                        </span>
                    </Tooltip>
                }
            />
        </ThemeProvider>
    );
}
