import axios from "axios";
import { connections } from "../config";
import { Product } from "../types/product.type";
import { ApiHeaders } from "../types/auth.types";
import { PRODUCTS_ACTIONS_ALLOWED_ACCESSES } from "../constants/access.constant";


const ItemApiClient = axios.create({ baseURL: connections.itemApiUrl });

export async function ListProducts(sessionToken: string): Promise<Product[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(PRODUCTS_ACTIONS_ALLOWED_ACCESSES),
        };
        const res = await ItemApiClient.get("/products/list", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'ProductService.ListProducts':", err);
        throw err;
    }
}

export async function UpdateProduct(id: number, data: Partial<Product>, sessionToken: string) {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(PRODUCTS_ACTIONS_ALLOWED_ACCESSES),
        };
        const res = await ItemApiClient.patch(`/product/${id}`, data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'ProductService.UpdateProduct':", err);
        throw err;
    }
}

export async function CreateProduct(sessionToken: string, data: Partial<Product>): Promise<Product> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(PRODUCTS_ACTIONS_ALLOWED_ACCESSES),
        };
        const res = await ItemApiClient.post("/product/", data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'ProductService.CreateProduct':", err);
        throw err;
    }
}

export async function DeleteProduct(id: number, sessionToken: string) {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(PRODUCTS_ACTIONS_ALLOWED_ACCESSES),
        };
        const res = await ItemApiClient.delete(`/product/${id}`, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'ProductService.DeleteProduct':", err);
        throw err;
    }
}
