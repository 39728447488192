import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useQueryClient } from "@tanstack/react-query";

import AODataTable from "../../components/Table/DataTable";
import { Stock } from "../../types/stock.types";
import { Item } from "../../types/item.types";
import * as StockService from "../../services/stock.service";
import { makeItemsGridColumnDefinition } from "../../utils/item";
import { ErrorToast, InfoToast, SuccessToast } from "../../utils/toaster";
import UserWrapper from "../../wrappers/user.wrapper";
import { CheckIfUserHasPermission } from "../../utils/access";
import { EDIT_ITEM_ALLOWED_ACCESSES } from "../../constants/access.constant";
import GenericDialog from "../../components/Dialogs/Generic";
import ItemForm from "../../components/Forms/ItemForm";
import { useStockItems } from "../../hooks";


export default function DetailedStock() {
    const [stock, setStock] = useState<Stock>();
    const [isLoading, setIsLoading] = useState(true);
    const [showItemFormDialog, setShowItemFormDialog] = useState(false);
    const [itemToEdit, setItemToEdit] = useState<Item>();
    const [cookies] = useCookies(['session_token']);
    const { id } = useParams();
    const stockId = Number(id);
    const navigate = useNavigate();
    const user = UserWrapper.get();
    const queryClient = useQueryClient();
    const { loadingItems, stockItems } = useStockItems(stock?.Id!);

    const totalItemsAmount = stockItems?.reduce((acc, curr) => acc + curr.Amount, 0);
    const isUserAllowedToEdit = (stockId: number) => CheckIfUserHasPermission(
        user.access!,
        EDIT_ITEM_ALLOWED_ACCESSES(stockId),
    );
    const columns = makeItemsGridColumnDefinition();
    const toggleItemFormDialog = (submitted?: boolean) => {
        if (typeof submitted === "boolean" && submitted) {
            queryClient.invalidateQueries(['stockItems', stock?.Id]).then(() => {
                SuccessToast("Ítem atualizado!");
            });
        }
        setShowItemFormDialog(!showItemFormDialog);
    };
    const handleEditButtonClick = (i: Item) => {
        setItemToEdit(i);
        toggleItemFormDialog();
    };
    const actions: GridColDef<Item>[] = [
        {
            field: "Actions",
            headerName: "Ações",
            width: 150,
            align: "center",
            headerAlign: "center",
            colSpan: 4,
            renderCell: (params) => (
                <span>
                    <Tooltip
                        title={!isUserAllowedToEdit(params.row.Id) ? "Você não tem permissão pra fazer isso" : "Editar"}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToEdit(params.row.StockId)} onClick={(e) => handleEditButtonClick(params.row)}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </span>
            ),
        },
    ];

    useEffect(() => {
        if (stockId) {
            StockService.GetStock(stockId, cookies.session_token).then(async (data) => setStock(data))
            .catch((err) => {
                if (err.response.status === 404) {
                    navigate("/stocks");
                    InfoToast("O estoque que você tentou visualizar não existe, por isso te redirecionamos pra cá!");
                }
                else {
                    ErrorToast("Por algum motivo não estamos conseguindo carregar esse estoque! Por favor tente novamente ou contate um administrador!");
                }
            })
            .finally(() => setIsLoading(false));
        }
        else navigate("/stocks");
    }, [cookies.session_token, stockId, navigate]);

    return (
        <>
            <Helmet title="Admin ONE | Estoque Detalhado" />
            <GenericDialog
                onClose={toggleItemFormDialog}
                open={showItemFormDialog}
                title="Editar Ítem"
            >
                <ItemForm
                    item={itemToEdit}
                    onClose={toggleItemFormDialog}
                />
            </GenericDialog>
            <AODataTable
                useDefaultPreComponent
                columns={[
                    ...columns,
                    ...actions,
                ]}
                rows={stockItems!}
                isLoading={isLoading || loadingItems}
                title={isLoading ? "Carregando..." : `📦 ${stock?.Name}`}
                preComponentAction={
                    !isLoading && !loadingItems ? (
                        <span style={{ display: "flex", gap: "1rem", alignItems: "center", color: "#fff" }}>
                            <span>
                                Quantidade de ítens: <strong>{totalItemsAmount}</strong>
                            </span>
                        </span>
                    )
                    :
                    <></>
                }
            />
        </>
    )
}
