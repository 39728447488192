import React, { useEffect, useState } from "react";
import { Button, Checkbox, CircularProgress, FormControl, FormHelperText, InputLabel, ListItemText, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import { useCookies } from "react-cookie";

import * as UserService from "../../../services/user.service";
import { CreateUserData, DepartmentProfile, StockProfile, SystemProfile, User } from "../../../types/user.types";
import { ACCESS_LEVELS } from "../../../constants/access.constant";
import { BRANCHES } from "../../../constants/generic.constant";
import { ErrorToast, InfoToast } from "../../../utils/toaster";
import { useDepartments, useStocks } from "../../../hooks";
import "./UserForm.styles.scss";


type UserFormProps = {
    isEditing?: boolean;
    onClose?: (arg?: any) => void;
    user?: User;
    isCoffeeShop?: boolean;
};

type AccessLevelForSelection = {
    level?: number;
    levelName?: string;
    resourceId?: number;
    resourceName?: string;
};

export default function UserForm({ isEditing, onClose, user, isCoffeeShop }: UserFormProps) {
    const [firstName, setFirstName] = useState({ error: false, helperTxt: "", value: "" });
    const [lastName, setLastName] = useState({ error: false, helperTxt: "", value: "" });
    const [email, setEmail] = useState({ error: false, helperTxt: "", value: "" });
    const [branch, setBranch] = useState({ error: false, helperTxt: "", value: String(BRANCHES.RJ) });
    const [systemAccessLevel, setSystemAccessLevel] = useState({ error: false, helperTxt: "", value: "" });
    const [departmentsAccessLevels, setDepartmentsAccessLevels] = useState<AccessLevelForSelection[]>([]);
    const [stocksAccessLevels, setStocksAccessLevels] = useState<AccessLevelForSelection[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cookies] = useCookies(['session_token']);
    const { loadingStocks, stocks } = useStocks();
    const { departments, loadingDepartments } = useDepartments();
    const isGmail = email.value && email.value.includes("@gmail.com");
    const hasAnyError = firstName.error || lastName.error || email.error || branch.error || systemAccessLevel.error;

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement> ) => setFirstName({ error: false, helperTxt: "", value: e.target.value });
    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setLastName({ error: false, helperTxt: "", value: e.target.value });
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail({ error: false, helperTxt: "", value: e.target.value });
    const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { value } = e.target;
        let error = false;
        if (!isGmail) error = true;
        setEmail({ ...email, error, helperTxt: error ? "A plataforma só é compatível com e-mails do Gmail!" : "", value });
    }
    const handleBranchChange = (value: string) => setBranch({ error: false, helperTxt: "", value });
    const handleSystemAccessLevelChange = (value: string) => {
        if (value === systemAccessLevel.value) {
            setSystemAccessLevel({ error: false, helperTxt: "", value: "" });
        }
        else setSystemAccessLevel({ error: false, helperTxt: "", value });
    };
    const handleDepartmentsAccessLevelsChange = (value: AccessLevelForSelection) => {
        if (!departmentsAccessLevels.find((dal) => dal.resourceId === value.resourceId)) {
            setDepartmentsAccessLevels(prev => [...prev, value]);
        }
        else if (departmentsAccessLevels.find((dal) => dal.level === value.level && dal.resourceId === value.resourceId)) {
            setDepartmentsAccessLevels(prev => {
                const updated = [...prev];
                const [indexToRemove] = departmentsAccessLevels.map((dal, i) => {
                    if (dal.level === value.level && dal.resourceId === value.resourceId) return i;
                    return -1;
                });
                updated.splice(indexToRemove, 1);
                return updated;
            });
            setStocksAccessLevels(prev => {
                const updated = [...prev];
                const [indexToRemove] = stocksAccessLevels.map((sal, i) => {
                    const stock = stocks?.find((s) => s.Id === sal.resourceId);
                    if (stock?.DepartmentId === value.resourceId) return i;
                    return false;
                });

                if (typeof indexToRemove === "number") updated.splice(indexToRemove, 1);
                return updated;
            });
        }
        else {
            setDepartmentsAccessLevels(prev => {
                const updated = [...prev].filter((p) => p.resourceId !== value.resourceId);
                return [...updated, value];
            });
        }
    };

    const handleStocksAccessLevelsChange = (value: AccessLevelForSelection) => {
        if (!stocksAccessLevels.find((sal) => sal.resourceId === value.resourceId)) {
            setStocksAccessLevels(prev => [...prev, value]);
        }
        else if (stocksAccessLevels.find((sal) => sal.level === value.level && sal.resourceId === value.resourceId)) {
            setStocksAccessLevels(prev => {
                const updated = [...prev];
                const [indexToRemove] = stocksAccessLevels.map((sal, i) => {
                    if (sal.level === value.level && sal.resourceId === value.resourceId) return i;
                    return -1
                });
                updated.splice(indexToRemove, 1);
                return updated;
            });
        }
        else {
            setStocksAccessLevels(prev => {
                const updated = [...prev].filter((p) => p.resourceId !== value.resourceId);
                return [...updated, value];
            });
        }
    };

    const makePatchData = () => {
        const patchData: Partial<CreateUserData> = {};
        if (firstName.value !== user?.FirstName) patchData.FirstName = firstName.value;
        if (lastName.value !== user?.LastName) patchData.LastName = lastName.value;
        if (Number(branch.value) !== user?.BranchId) patchData.BranchId = Number(branch.value);
        if (
            !!departmentsAccessLevels?.filter((dal) => !user?.DepartmentUserProfile.some((udup) => udup.DepartmentId === dal.resourceId && udup.AccessLevelId === dal.level )).length
            ||
            departmentsAccessLevels.length !== user?.DepartmentUserProfile.length
        ) {
            patchData.DepartmentUserProfile = departmentsAccessLevels.map((dal) => (
                {
                    AccessLevelId: dal.level,
                    DepartmentId: dal.resourceId,
                } as DepartmentProfile
            ));
        }
        if (
            !!stocksAccessLevels?.filter((sal) => !user?.StockUserProfile.some((usup) => usup.StockId === sal.resourceId && usup.AccessLevelId === sal.level)).length
            ||
            stocksAccessLevels.length !== user?.StockUserProfile.length
        ) {
            patchData.StockUserProfile = stocksAccessLevels.map((sal) => (
                {
                    AccessLevelId: sal.level,
                    StockId: sal.resourceId,
                } as StockProfile
            ));
        }
        if (systemAccessLevel.value && Number(systemAccessLevel.value) !== user?.SystemUserProfile?.[0]?.AccessLevelId) {
            patchData.SystemUserProfile = { AccessLevelId: Number(systemAccessLevel.value) } as SystemProfile;
        }

        return patchData;
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        let isValid = true;
        if (!firstName.value) {
            isValid = false;
            setFirstName({ ...firstName, error: true, helperTxt: "Esse campo é obrigatório!" });
        }
        if (!lastName.value) {
            isValid = false;
            setLastName({ ...lastName, error: true, helperTxt: "Esse campo é obrigatório!" });
        }
        if (!email.value) {
            isValid = false;
            setEmail({ ...email, error: true, helperTxt: "Esse campo é obrigatório!" });
        }
        if (!isGmail) {
            isValid = false;
            setEmail({ ...email, error: true, helperTxt: "A plataforma só é compatível com e-mails do Gmail!" });
        }
        if (!branch.value) {
            isValid = false;
            setBranch({ ...branch, error: true, helperTxt: "Esse campo é obrigatório!" });
        }

        if (isValid) {
            const data: CreateUserData = {
                FirstName: firstName.value,
                LastName: lastName.value,
                Email: email.value,
                BranchId: Number(branch.value),
                DepartmentUserProfile: departmentsAccessLevels.map((dal) => (
                    {
                        AccessLevelId: dal.level,
                        DepartmentId: dal.resourceId,
                    } as DepartmentProfile
                )),
                StockUserProfile: stocksAccessLevels.map((sal) => (
                    {
                        AccessLevelId: sal.level,
                        StockId: sal.resourceId,
                    } as StockProfile
                )),
                SystemUserProfile: systemAccessLevel.value ?
                    {
                        AccessLevelId: Number(systemAccessLevel.value),
                    } as SystemProfile
                :
                undefined,
            };
            setIsSubmitting(true);
            if (isEditing && user) {
                const patchData = makePatchData();
                if (!Object.entries(patchData).length) {
                    InfoToast("Nada mudou! Antes de salvar, por favor altere alguma informação do usuário!");
                    setIsSubmitting(false);
                }
                else {
                    UserService.UpdateUser(user?.Id!, cookies.session_token, patchData)
                    .then((data) => onClose?.(true))
                    .catch((err) => {
                        if (err?.response?.status === 404) {
                            InfoToast('O usuário que você está tentando editar não existe! Por favor atualize a página!');
                        }
                        else {
                            ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                        }
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
                }
            }
            else {
                UserService.CreateUser(cookies.session_token!, data)
                .then((data) => onClose?.(true))
                .catch((err) => {
                    if (err?.response?.status === 409 && err?.response?.data?.includes('Unique constraint failed on the constraint: `Email`')) {
                        ErrorToast('Já existe um usuário com esse e-mail!');
                    }
                    else {
                        ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
            }
        }
    };
    const inputRenderFn = (selected: AccessLevelForSelection[]) => {
        const names = selected.map((s) => `${s.resourceName} (${s.levelName})`);
        return names.join(", ");
    };
    const permissionOptions = isCoffeeShop ?
        Object.values(ACCESS_LEVELS).filter((al) => al.id === ACCESS_LEVELS.coffee_shop_manager.id || al.id === ACCESS_LEVELS.coffee_shop_worker.id)
        :
        Object.values(ACCESS_LEVELS)
    ;

    useEffect(() => {
        if (isEditing && user) {
            setFirstName((prev) => ({ ...prev, value: user.FirstName }));
            setLastName((prev) => ({ ...prev, value: user.LastName }));
            setBranch((prev) => ({ ...prev, value: String(user.BranchId) }));
            setEmail((prev) => ({ ...prev, value: user.Email }));
            const dals = user.DepartmentUserProfile.map((dup) => (
                {
                    level: dup.AccessLevelId,
                    levelName: Object.values(ACCESS_LEVELS).find((v) => v.id === dup.AccessLevelId)?.friendlyName,
                    resourceId: dup.DepartmentId,
                    resourceName: departments?.find((d) => d.Id === dup.DepartmentId)?.Name,
                } as AccessLevelForSelection
            ));
            const sals = user.StockUserProfile.map((sal) => (
                {
                    level: sal.AccessLevelId,
                    levelName: Object.values(ACCESS_LEVELS).find((v) => v.id === sal.AccessLevelId)?.friendlyName,
                    resourceId: sal.StockId,
                    resourceName: stocks?.find((s) => s.Id === sal.StockId)?.Name,
                } as AccessLevelForSelection
            ));
            setDepartmentsAccessLevels(dals);
            setStocksAccessLevels(sals);
            if (user.SystemUserProfile.length) {
                setSystemAccessLevel((prev) => ({ ...prev, value: String(user.SystemUserProfile[0].AccessLevelId) }));
            }
        }
    }, [isEditing, user, departments, stocks]);

    return (
        <div className="create-user-form">
            <div className="basic-info">
                <h5>Informações Básicas</h5>
                <TextField
                    variant="outlined"
                    name="firstName"
                    label="Nome"
                    value={firstName.value}
                    onChange={handleFirstNameChange}
                    error={firstName.error}
                    helperText={firstName.helperTxt}
                    fullWidth
                    required
                />
                <TextField
                    variant="outlined"
                    name="lastName"
                    label="Sobrenome"
                    value={lastName.value}
                    onChange={handleLastNameChange}
                    error={lastName.error}
                    helperText={lastName.helperTxt}
                    fullWidth
                    required
                />
                <TextField
                    variant="outlined"
                    label="E-mail"
                    value={email.value}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    error={email.error}
                    helperText={email.helperTxt}
                    fullWidth
                    required
                />
                <FormControl>
                    <InputLabel id="branch-label" required error={branch.error}>Filial</InputLabel>
                    <Select
                        labelId="branch-label"
                        id="branch"
                        label="Filial"
                        onChange={(e) => handleBranchChange(e.target.value)}
                        value={branch.value}
                        error={branch.error}
                        required
                    >
                        <MenuItem value={String(BRANCHES.RJ)}>ONE RJ</MenuItem>
                    </Select>
                    {branch.error && branch.helperTxt && <FormHelperText>{branch.helperTxt}</FormHelperText>}
                </FormControl>
            </div>
            <div className="permissions">
                <h5>Permissões</h5>
                <FormControl>
                    <InputLabel id="system-access-level-label" error={systemAccessLevel.error}>Nível de acesso no sistema</InputLabel>
                    <Select
                        labelId="system-access-level-label"
                        id="access-level"
                        label="Nível de acesso no sistema"
                        renderValue={(selected) => permissionOptions.find((al) => al.id === Number(selected))?.friendlyName}
                        value={systemAccessLevel.value}
                        error={systemAccessLevel.error}
                    >
                        {permissionOptions.map((al, i) => (
                            <MenuItem
                                value={String(al.id)}
                                key={i}
                                onClick={(e) => handleSystemAccessLevelChange(String(al.id))}
                                disabled={isCoffeeShop && al.id !== ACCESS_LEVELS.coffee_shop_manager.id && al.id !== ACCESS_LEVELS.coffee_shop_worker.id}
                            >
                                <Checkbox checked={systemAccessLevel.value === String(al.id)} />
                                <ListItemText primary={al.friendlyName} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {!isCoffeeShop &&
                    <FormControl>
                        <InputLabel
                            id="department-access-level-label"
                            disabled={loadingDepartments || !departments?.length || isCoffeeShop}
                        >
                            Nível de acesso em departamentos
                        </InputLabel>
                        <Select
                            labelId="department-access-level-label"
                            id="department-access-level"
                            label="Nível de acesso em departamentos"
                            renderValue={inputRenderFn}
                            value={departmentsAccessLevels}
                            disabled={loadingDepartments || !departments?.length || isCoffeeShop}
                            multiple
                        >
                            {departments?.map((d, i) => (
                                <span key={i}>
                                    <ListSubheader>{d.Name}</ListSubheader>
                                    <MenuItem
                                        onClick={(e) => handleDepartmentsAccessLevelsChange({
                                            level: ACCESS_LEVELS.admin.id,
                                            levelName: ACCESS_LEVELS.admin.friendlyName,
                                            resourceId: d.Id,
                                            resourceName: d.Name
                                        })}
                                    >
                                        <Checkbox checked={!!departmentsAccessLevels?.find((dal) => d.Id === dal?.resourceId && dal?.level === ACCESS_LEVELS.admin.id)} />
                                        <ListItemText primary={ACCESS_LEVELS.admin.friendlyName} />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) => handleDepartmentsAccessLevelsChange({
                                            level: ACCESS_LEVELS.manager.id,
                                            levelName: ACCESS_LEVELS.manager.friendlyName,
                                            resourceId: d.Id,
                                            resourceName: d.Name
                                        })}
                                    >
                                        <Checkbox checked={!!departmentsAccessLevels?.find((dal) => d.Id === dal?.resourceId && dal?.level === ACCESS_LEVELS.manager.id)} />
                                        <ListItemText primary={ACCESS_LEVELS.manager.friendlyName} />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) => handleDepartmentsAccessLevelsChange({
                                            level: ACCESS_LEVELS.viewer.id,
                                            levelName: ACCESS_LEVELS.viewer.friendlyName,
                                            resourceId: d.Id,
                                            resourceName: d.Name
                                        })}
                                    >
                                        <Checkbox checked={!!departmentsAccessLevels?.find((dal) => d.Id === dal?.resourceId && dal?.level === ACCESS_LEVELS.viewer.id)} />
                                        <ListItemText primary={ACCESS_LEVELS.viewer.friendlyName} />
                                    </MenuItem>
                                </span>
                            ))}
                        </Select>
                        {!departments?.length && <FormHelperText sx={{ color: "orange" }}>Não há departamentos disponíveis para seleção</FormHelperText>}
                    </FormControl>
                }
                {!isCoffeeShop &&
                    <FormControl>
                        <InputLabel
                            id="stock-access-level-label"
                            disabled={loadingStocks || !stocks?.length || !departmentsAccessLevels.length || isCoffeeShop}
                        >
                            Nível de acesso em estoques
                        </InputLabel>
                        <Select
                            labelId="stock-access-level-label"
                            id="stock-access-level"
                            label="Nível de acesso em estoques"
                            value={stocksAccessLevels}
                            renderValue={inputRenderFn}
                            disabled={loadingStocks || !stocks?.length || !departmentsAccessLevels.length || isCoffeeShop}
                            multiple
                        >
                            {stocks?.filter((s) => departmentsAccessLevels.some((dal) => dal.resourceId === s.DepartmentId)).map((s, i) => (
                                <span key={i}>
                                    <ListSubheader>{departments?.find((d) => s.DepartmentId === d.Id)?.Name} - Estoque de {s.Name}</ListSubheader>
                                    <MenuItem
                                        onClick={(e) => handleStocksAccessLevelsChange({
                                            level: ACCESS_LEVELS.admin.id,
                                            levelName: ACCESS_LEVELS.admin.friendlyName,
                                            resourceId: s.Id,
                                            resourceName: s.Name
                                        })}
                                    >
                                        <Checkbox checked={!!stocksAccessLevels?.find((sal) => s.Id === sal?.resourceId && sal?.level === ACCESS_LEVELS.admin.id)} />
                                        <ListItemText primary={ACCESS_LEVELS.admin.friendlyName} />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) => handleStocksAccessLevelsChange({
                                            level: ACCESS_LEVELS.manager.id,
                                            levelName: ACCESS_LEVELS.manager.friendlyName,
                                            resourceId: s.Id,
                                            resourceName: s.Name
                                        })}
                                    >
                                        <Checkbox checked={!!stocksAccessLevels?.find((sal) => s.Id === sal?.resourceId && sal?.level === ACCESS_LEVELS.manager.id)} />
                                        <ListItemText primary={ACCESS_LEVELS.manager.friendlyName} />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) => handleStocksAccessLevelsChange({
                                            level: ACCESS_LEVELS.viewer.id,
                                            levelName: ACCESS_LEVELS.viewer.friendlyName,
                                            resourceId: s.Id,
                                            resourceName: s.Name
                                        })}
                                    >
                                        <Checkbox checked={!!stocksAccessLevels?.find((sal) => s.Id === sal?.resourceId && sal?.level === ACCESS_LEVELS.viewer.id)} />
                                        <ListItemText primary={ACCESS_LEVELS.viewer.friendlyName} />
                                    </MenuItem>
                                </span>
                            ))}
                        </Select>
                        {!stocks?.length ?
                            <FormHelperText sx={{ color: "orange" }}>Não há estoques disponíveis para seleção</FormHelperText>
                            :
                            !departmentsAccessLevels.length && !isCoffeeShop && <FormHelperText sx={{ color: "orange" }}>Selecione um departamento primeiro</FormHelperText>
                        }
                    </FormControl>
                }
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={hasAnyError || isSubmitting}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" size={24} />
                    :
                    isEditing ? 'Salvar' : 'Criar'
                }
            </Button>
        </div>
    );
}
