import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { GridColDef } from "@mui/x-data-grid";
import { useLocation, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Avatar, CssBaseline, ThemeProvider, Tooltip } from "@mui/material";

import AODataTable from "../../components/Table/DataTable";
import { ItemSale, ProductSale, Sale } from "../../types/sales.type";
import * as SalesService from "../../services/sales.service";
import { useUsers } from "../../hooks";
import BlankAvatar from "../../assets/png/blank-avatar.png";
import { coffeeShopTheme, defaultTheme } from "../../themes";


export default function DetailedSale() {
    const [sale, setSale] = useState<Sale>();
    const [rows, setRows] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [cookies] = useCookies(['session_token']);
    const { id } = useParams();
    const location = useLocation();
    const isCoffeeShop = location.pathname.includes("/coffee-shop");
    const saleId = Number(id);
    const { loadingUsers, users } = useUsers();
    const saleOwner = users?.find((u) => u.Id === sale?.UserId);
    const columns: GridColDef<ProductSale & ItemSale>[] = [
        {
            field: "Name",
            headerName: "Nome",
            width: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Item?.Name || params.row.Product?.Name,
        },
        {
            field: "Amount",
            headerName: "Quantidade",
            width: 250,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "TotalValue",
            headerName: "Valor",
            width: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `R$${Number(params.row.TotalValue).toFixed(2)}`,
        },
        {
            field: "CreatedDate",
            headerName: "Vendido em",
            width: 250,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => new Date(params.value).toLocaleString(),
        },
    ];


    useEffect(() => {
        if (saleId) {
            SalesService.GetSale(saleId, cookies.session_token).then((data) => {
                let _rows = [];
                if (data.ItemSale) _rows.push(...data.ItemSale);
                if (data.ProductSale) _rows.push(...data.ProductSale);
                setSale(data);
                setRows(_rows);
                setIsLoading(false);
            });
        }
    }, [saleId, cookies.session_token]);

    return (
        <ThemeProvider theme={isCoffeeShop ? coffeeShopTheme : defaultTheme}>
            <CssBaseline />
            <Helmet title="Admin ONE | Venda Detalhada" />
            <AODataTable
                useDefaultPreComponent
                columns={columns}
                rows={rows}
                isLoading={isLoading || loadingUsers}
                title={isLoading ? "Carregando..." : "Venda detalhada"}
                preComponentAction={
                    !isLoading ? (
                        <span style={{ display: "flex", gap: "1rem", alignItems: "center", color: "#fff" }}>
                            <span>
                                Receita: <strong>R${Number(sale?.TotalValue).toFixed(2)}</strong>
                            </span>
                            <span>-</span>
                            <span>Criada por:</span>
                            <span>
                                <Tooltip
                                    arrow
                                    title={`${saleOwner?.FirstName} ${saleOwner?.LastName}`}
                                >
                                    <Avatar src={saleOwner?.Picture || BlankAvatar} />
                                </Tooltip>
                            </span>
                        </span>
                    )
                    :
                    <></>
                }
            />
        </ThemeProvider>
    );
}
