import axios from "axios";
import { GenerateInternalToken } from "@one-tech/access-control-lib";

import { EDIT_ITEM_ALLOWED_ACCESSES, LIST_ITEMS_ALLOWED_ACCESSES, LIST_STOCK_ITEMS_ALLOWED_ACCESSES } from "../constants/access.constant";
import { ApiHeaders } from "../types/auth.types";
import { connections } from "../config";
import { Category, Item } from "../types/item.types";
import { APP_ID } from "../constants/generic.constant";


const ItemApiClient = axios.create({ baseURL: connections.itemApiUrl });

export async function ListItems(sessionToken: string): Promise<Item[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(LIST_ITEMS_ALLOWED_ACCESSES)
        };
        const res = await ItemApiClient.get("/items/list", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'ItemService.ListItems':", err);
        throw err;
    }
}

export async function ListCategories(): Promise<Category[]> {
    try {
        const headers = { Authorization: await GenerateInternalToken(APP_ID) };
        const res = await ItemApiClient.get("/categories/list", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'ItemService.ListCategories':", err);
        throw err;
    }
}

export async function ListStockItems(stockId: number, sessionToken: string): Promise<Item[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(LIST_STOCK_ITEMS_ALLOWED_ACCESSES(stockId)),
        };
        const res = await ItemApiClient.get(`/items/stock/${stockId}`, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'ItemService.GetItem':", err);
        throw err;
    }
}

export async function UpdateItem(id: number, stockId: number, data: Partial<Item>, sessionToken: string) {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(EDIT_ITEM_ALLOWED_ACCESSES(stockId)),
        };
        const res = await ItemApiClient.patch(`/item/${id}`, data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'ItemService.UpdateItem':", err);
        throw err;
    }
}
