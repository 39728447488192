import axios from "axios";
import { GenerateInternalToken } from "@one-tech/access-control-lib"

import { connections } from "../config";
import { APP_ID } from "../constants/generic.constant";
import { CreateUserData, GoogleUser, User } from "../types/user.types";
import { ApiHeaders } from "../types/auth.types";
import {
    ALL_COFFEE_SHOP_ACCESSES,
    CREATE_USER_ALLOWED_ACCESSES,
    DELETE_USER_ALLOWED_ACCESSES,
    LIST_USERS_ALLOWED_ACCESSES
} from "../constants/access.constant";


const UserApiClient = axios.create({ baseURL: connections.userApiUrl });

export async function CheckUserAuth(googleAccessToken: string): Promise<{ user: User, googleUser: GoogleUser } | null> {
    try {
        const headers = { Authorization: await GenerateInternalToken(APP_ID) };
        const res = await UserApiClient.post("/check-auth", { googleAccessToken }, { headers });
        return res.data;
    } catch (err: any) {
        console.error("Error in 'UserService.CheckUserAuth':", err);
        if (err?.response?.status === 403) return null;
        throw err;
    }
}

export async function ListUsers(sessionToken: string): Promise<User[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(
                [
                    ...LIST_USERS_ALLOWED_ACCESSES,
                    ...ALL_COFFEE_SHOP_ACCESSES,
                ]
            ),
        };
        const res = await UserApiClient.get("/users/list", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'UserService.ListUsers':", err);
        throw err;
    }
}

export async function CreateUser(sessionToken: string, data: CreateUserData): Promise<User> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(CREATE_USER_ALLOWED_ACCESSES),
        };
        const res = await UserApiClient.post("/", data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'UserService.CreateUser':", err);
        throw err;
    }
}

export async function GetUserById(id: number): Promise<User | null> {
    try {
        const headers = { Authorization: await GenerateInternalToken(APP_ID), };
        const res = await UserApiClient.get(`/${id}`, { headers });
        return res.data;
    } catch (err: any) {
        console.error("Error in 'UserService.GetUserById':", err);
        if (err?.response?.status === 404) return null;
        throw err;
    }
}

export async function DeleteUser(id: number, sessionToken: string) {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(DELETE_USER_ALLOWED_ACCESSES)
        };

        const res = await UserApiClient.delete(`/${id}`, { headers });
        return res.data;
    } catch (err: any) {
        console.error("Error in 'UserService.DeleteUser':", err);
        throw err;
    }
}

export async function UpdateUser(id: number, sessionToken: string, data: Partial<CreateUserData>) {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(CREATE_USER_ALLOWED_ACCESSES),
        };

        const res = await UserApiClient.patch(`/${id}`, data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'UserService.UpdateUser':", err);
        throw err;
    }
}
