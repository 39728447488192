import { AllowedAccess } from "../types/auth.types";


export const ACCESS_LEVELS = {
    admin: {
        id: 1,
        friendlyName: "Admin",
    },
    manager: {
        id: 2,
        friendlyName: "Gerente",
    },
    viewer: {
        id: 3,
        friendlyName: "Visualizador",
    },
    sale_admin: {
        id: 4,
        friendlyName: "Admin de vendas",
    },
    sale_manager: {
        id: 5,
        friendlyName: "Gerente de vendas",
    },
    sale_viewer: {
        id: 6,
        friendlyName: "Visualizador em fluxos de venda",
    },
    purchase_admin: {
        id: 7,
        friendlyName: "Admin de compras",
    },
    purchase_manager: {
        id: 8,
        friendlyName: "Gerente de compras",
    },
    purchase_viewer: {
        id: 9,
        friendlyName: "Visualizador em fluxos de compra",
    },
    stock_admin: {
        id: 10,
        friendlyName: "Admin de estoque",
    },
    stock_manager: {
        id: 11,
        friendlyName: "Gerente de estoque",
    },
    stock_viewer: {
        id: 12,
        friendlyName: "Visualizador de estoque",
    },
    finance_admin: {
        id: 13,
        friendlyName: "Admin de finanças",
    },
    finance_manager: {
        id: 14,
        friendlyName: "Gerente de finanças",
    },
    finance_viewer: {
        id: 15,
        friendlyName: "Visualizador em fluxos de finança",
    },
    coffee_shop_manager: {
        id: 16,
        friendlyName: "Gerente do Coffee Shop",
    },
    coffee_shop_worker: {
        id: 17,
        friendlyName: "Funcionário do Coffee Shop",
    },
};

export const SYSTEM_ADMIN_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.admin.id,
    resource: "systems"
};

export const SYSTEM_MANAGER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.manager.id,
    resource: "systems"
};

export const SYSTEM_VIEWER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.viewer.id,
    resource: "systems",
};

export const SYSTEM_FINANCE_ADMIN_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.finance_admin.id,
    resource: "systems",
};

export const SYSTEM_FINANCE_MANAGER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.finance_manager.id,
    resource: "systems",
};

export const SYSTEM_FINANCE_VIEWER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.finance_viewer.id,
    resource: "systems",
};

export const SYSTEM_PURCHASE_ADMIN_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.purchase_admin.id,
    resource: "systems",
};

export const SYSTEM_PURCHASE_MANAGER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.purchase_manager.id,
    resource: "systems",
};

export const SYSTEM_PURCHASE_VIEWER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.purchase_viewer.id,
    resource: "systems",
};

export const SYSTEM_SALE_ADMIN_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.sale_admin.id,
    resource: "systems",
};

export const SYSTEM_SALE_MANAGER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.sale_manager.id,
    resource: "systems",
};

export const SYSTEM_SALE_VIEWER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.sale_viewer.id,
    resource: "systems",
};

export const SYSTEM_STOCK_ADMIN_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.stock_admin.id,
    resource: "systems",
};

export const SYSTEM_STOCK_MANAGER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.stock_manager.id,
    resource: "systems",
};

export const SYSTEM_STOCK_VIEWER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.stock_viewer.id,
    resource: "systems",
};

export const COFFEE_SHOP_MANAGER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.coffee_shop_manager.id,
    resource: "systems",
};

export const COFFEE_SHOP_WORKER_ACCESS: AllowedAccess = {
    levelId: ACCESS_LEVELS.coffee_shop_worker.id,
    resource: "systems",
};

export const ALL_SYSTEM_MANAGING_ACCESSES = [
    SYSTEM_ADMIN_ACCESS,
    SYSTEM_MANAGER_ACCESS,
];

export const ALL_SYSTEM_FINANCE_ACCESSES = [
    SYSTEM_FINANCE_ADMIN_ACCESS,
    SYSTEM_FINANCE_MANAGER_ACCESS,
    SYSTEM_FINANCE_VIEWER_ACCESS,
    SYSTEM_PURCHASE_ADMIN_ACCESS,
    SYSTEM_PURCHASE_MANAGER_ACCESS,
    SYSTEM_PURCHASE_VIEWER_ACCESS,
    SYSTEM_SALE_ADMIN_ACCESS,
    SYSTEM_SALE_MANAGER_ACCESS,
    SYSTEM_SALE_VIEWER_ACCESS,
];

export const ALL_SYSTEM_STOCK_ACCESSES = [
    SYSTEM_STOCK_ADMIN_ACCESS,
    SYSTEM_STOCK_MANAGER_ACCESS,
    SYSTEM_STOCK_VIEWER_ACCESS,
];

export const ALL_SYSTEM_ACCESSES = [
    SYSTEM_ADMIN_ACCESS,
    SYSTEM_MANAGER_ACCESS,
    SYSTEM_VIEWER_ACCESS,
    SYSTEM_FINANCE_ADMIN_ACCESS,
    SYSTEM_FINANCE_MANAGER_ACCESS,
    SYSTEM_FINANCE_VIEWER_ACCESS,
    SYSTEM_PURCHASE_ADMIN_ACCESS,
    SYSTEM_PURCHASE_MANAGER_ACCESS,
    SYSTEM_PURCHASE_VIEWER_ACCESS,
    SYSTEM_SALE_ADMIN_ACCESS,
    SYSTEM_SALE_MANAGER_ACCESS,
    SYSTEM_SALE_VIEWER_ACCESS,
    SYSTEM_STOCK_ADMIN_ACCESS,
    SYSTEM_STOCK_MANAGER_ACCESS,
    SYSTEM_STOCK_VIEWER_ACCESS,
];

export const ALL_COFFEE_SHOP_ACCESSES = [
    COFFEE_SHOP_MANAGER_ACCESS,
    COFFEE_SHOP_WORKER_ACCESS,
];

// Users
export const CREATE_USER_ALLOWED_ACCESSES = ALL_SYSTEM_MANAGING_ACCESSES;
export const LIST_USERS_ALLOWED_ACCESSES = ALL_SYSTEM_ACCESSES;
export const DELETE_USER_ALLOWED_ACCESSES = ALL_SYSTEM_MANAGING_ACCESSES;

// Departments
export const LIST_DEPARTMENTS_ALLOWED_ACCESSES = ALL_SYSTEM_ACCESSES;
export const DELETE_DEPARTMENT_ALLOWED_ACCESSES = (departmentId: number): AllowedAccess[] => [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    { levelId: ACCESS_LEVELS.admin.id, resource: "departments", resourceId: departmentId },
    { levelId: ACCESS_LEVELS.manager.id, resource: "departments", resourceId: departmentId },
];
export const VIEW_DEPARTMENT_ALLOWED_ACCESSES = (departmentId: number): AllowedAccess[] => [
    ...ALL_SYSTEM_ACCESSES,
    { levelId: ACCESS_LEVELS.admin.id, resource: "departments", resourceId: departmentId },
    { levelId: ACCESS_LEVELS.manager.id, resource: "departments", resourceId: departmentId },
    { levelId: ACCESS_LEVELS.viewer.id, resource: "departments", resourceId: departmentId },
];

export const EDIT_DEPARTMENT_ALLOWED_ACCESSES = (departmentId: number): AllowedAccess[] => [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    { levelId: ACCESS_LEVELS.admin.id, resource: "departments", resourceId: departmentId },
    { levelId: ACCESS_LEVELS.manager.id, resource: "departments", resourceId: departmentId },
];

// Stocks
export const LIST_STOCKS_ALLOWED_ACCESSES = [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    ...ALL_SYSTEM_FINANCE_ACCESSES,
];

export const VIEW_STOCK_ALLOWED_ACCESSES = (stockId: number): AllowedAccess[] => [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    ...ALL_SYSTEM_FINANCE_ACCESSES,
    { levelId: ACCESS_LEVELS.admin.id, resource: "stocks", resourceId: stockId },
    { levelId: ACCESS_LEVELS.manager.id, resource: "stocks", resourceId: stockId },
];

export const CREATE_STOCK_ALLOWED_ACCESSES: AllowedAccess[] = [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    ...ALL_SYSTEM_FINANCE_ACCESSES,
];

export const EDIT_STOCK_ALLOWED_ACCESSES = (stockId: number): AllowedAccess[] => [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    SYSTEM_STOCK_ADMIN_ACCESS,
    SYSTEM_STOCK_MANAGER_ACCESS,
    { levelId: ACCESS_LEVELS.admin.id, resource: "stocks", resourceId: stockId },
    { levelId: ACCESS_LEVELS.manager.id, resource: "stocks", resourceId: stockId },
];

export const DELETE_STOCK_ALLOWED_ACCESSES = (stockId: number): AllowedAccess[] => [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    SYSTEM_STOCK_ADMIN_ACCESS,
    SYSTEM_STOCK_MANAGER_ACCESS,
    { levelId: ACCESS_LEVELS.admin.id, resource: "stocks", resourceId: stockId },
    { levelId: ACCESS_LEVELS.manager.id, resource: "stocks", resourceId: stockId },
];

export const LIST_PURCHASES_ALLOWED_ACCESSES: AllowedAccess[] = ALL_SYSTEM_ACCESSES;

export const CREATE_PURCHASE_ALLOWED_ACCESSES = [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    SYSTEM_FINANCE_ADMIN_ACCESS,
    SYSTEM_FINANCE_MANAGER_ACCESS,
    SYSTEM_PURCHASE_ADMIN_ACCESS,
    SYSTEM_PURCHASE_MANAGER_ACCESS,
];

export const LIST_SALES_ALLOWED_ACCESSES: AllowedAccess[] = ALL_SYSTEM_ACCESSES;

export const CREATE_SALE_ALLOWED_ACCESSES: AllowedAccess[] = [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    ...ALL_COFFEE_SHOP_ACCESSES,
    SYSTEM_FINANCE_ADMIN_ACCESS,
    SYSTEM_FINANCE_MANAGER_ACCESS,
    SYSTEM_SALE_ADMIN_ACCESS,
    SYSTEM_SALE_MANAGER_ACCESS,
];

export const LIST_ITEMS_ALLOWED_ACCESSES = ALL_SYSTEM_ACCESSES;
export const EDIT_ITEM_ALLOWED_ACCESSES = (stockId: number): AllowedAccess[] => [
    ...ALL_SYSTEM_MANAGING_ACCESSES,
    { levelId: ACCESS_LEVELS.admin.id, resource: "stocks", resourceId: stockId },
    { levelId: ACCESS_LEVELS.manager.id, resource: "stocks", resourceId: stockId },
];

export const LIST_STOCK_ITEMS_ALLOWED_ACCESSES = (stockId: number): AllowedAccess[] => [
    ...ALL_SYSTEM_ACCESSES,
    { levelId: ACCESS_LEVELS.admin.id, resource: "stocks", resourceId: stockId },
    { levelId: ACCESS_LEVELS.manager.id, resource: "stocks", resourceId: stockId },
    { levelId: ACCESS_LEVELS.viewer.id, resource: "stocks", resourceId: stockId },
];

export const PRODUCTS_ACTIONS_ALLOWED_ACCESSES = [
    ...ALL_SYSTEM_ACCESSES,
    ...ALL_COFFEE_SHOP_ACCESSES,
];

export const COFFEE_SHOP_VIEW_ALLOWED_ACCESSES = [
    ...ALL_COFFEE_SHOP_ACCESSES,
    ...ALL_SYSTEM_MANAGING_ACCESSES,
];

export const COFFEE_SHOP_MANAGEMENT_ALLOWED_ACCESSES = [
    SYSTEM_ADMIN_ACCESS,
    COFFEE_SHOP_MANAGER_ACCESS,
];
