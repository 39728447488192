import React, { useEffect, useState } from "react";
import { Inventory as InventoryIcon } from "@mui/icons-material";
import { Autocomplete, Button, Checkbox, CircularProgress, FilterOptionsState, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, createFilterOptions } from "@mui/material";
import { NumberFormatBase } from "react-number-format";

import { WrappedItem } from "../../../types/item.types";
import { useCategories, useItems, usePurchaseTypes, useStocks } from "../../../hooks";
import { InfoToast, SuccessToast } from "../../../utils/toaster";
import { calculateNetCost, calculateTotalCost, calculateVariableCost, friendlyPurchaseType, makeUUID } from "../../../utils/purchase";
import PurchaseWrapper from "../../../wrappers/purchase.wrapper";
import { PURCHASE_TYPES } from "../../../constants/generic.constant";
import "./ItemPurchaseForm.styles.scss";


type PurchaseFormProps = {
    isEditing?: boolean;
    onClose?: (arg?: any) => void;
    purchaseItem?: WrappedItem;
};

type SelectedCategory = {
    inputValue?: string;
    name: string;
};

const filter = createFilterOptions<SelectedCategory>();

export default function ItemPurchaseForm({ isEditing, onClose, purchaseItem }: PurchaseFormProps) {
    const [name, setName] = useState("");
    const [purchaseTypeId, setPurchaseTypeId] = useState<number>();
    const [amount, setAmount] = useState(0);
    const [unitCost, setUnitCost] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [costVariablePercentage, setCostVariablePercentage] = useState(0);
    const [selectedStockId, setSelectedStockId] = useState<number>();
    const [selectedCategory, setSelectedCategory] = useState<SelectedCategory | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isStockReposition, setIsStockReposition] = useState(false);
    const [itemIdForReposition, setItemIdForReposition] = useState<number>();
    const { stocks, loadingStocks } = useStocks();
    const { purchaseTypes, loadingPurchaseTypes } = usePurchaseTypes();
    const { categories, loadingCategories } = useCategories();
    const { items, loadingItems } = useItems();
    const purchase = PurchaseWrapper.get();
    const itemForReposition = items?.find((i) => i.Id === itemIdForReposition);
    const hasAnyError = (!name && !isStockReposition) || (isStockReposition && !itemForReposition) || !purchaseTypeId || !amount || !selectedStockId;

    const mergedCategories = () => {
        const existingCategories: SelectedCategory[] = categories!.map((c) => ({ name: c.Name }));
        const categoriesInUse: SelectedCategory[] = purchase.Items.filter((i) => !!i.Category?.Name).map((i) => ({ name: i.Category?.Name! }));
        const merged = [...existingCategories, ...categoriesInUse];
        const reduced = merged.reduce((acc, curr) => ({ ...acc, [curr.name]: {} }), {});
        return Object.keys(reduced).map((key) => ({ name: key })) as SelectedCategory[];
    };
    const handleStockRepositionCheck = (checked: boolean) => setIsStockReposition(checked);
    const handleSelectedItemIdForReposition = (e: SelectChangeEvent) => setItemIdForReposition(Number(e.target.value));
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
    const handlePurchaseTypeSelection = (id: number) => setPurchaseTypeId(id);
    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setAmount(Number(e.target.value));
    const handleUnitPriceChange = (value: string) => {
        const fixed = (parseFloat(value) / 100).toFixed(2);
        setUnitCost(fixed);
    };
    const handleSalePriceChange = (value: string) => {
        const fixed = (parseFloat(value) / 100).toFixed(2);
        setSalePrice(fixed);
    };
    const handleCostVariablePercentChange = (value: string) => setCostVariablePercentage(Number(value));
    const formatPrice = (value: string) => {
        if (!value) return '';
        const formatted = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2
        }).format(parseFloat(value) / 100);
        return formatted;
    };
    const formatCostVariablePercent = (value: string) => {
        if (!value) return '';
        const formatted = new Intl.NumberFormat("pt-BR", {
            style: "percent",
            maximumFractionDigits: 2,
        }).format(Number(value) / 100);
        return formatted;
    };
    const handleStockSelection = (id: number) => setSelectedStockId(id);
    const handleCategoryChange = (value: string | SelectedCategory | null) => {
        if (typeof value === "string") setSelectedCategory({ name: value });
        else if (value && value.inputValue) setSelectedCategory({ name: value.inputValue });
        else setSelectedCategory(value);
    };
    const filterCategoryOptions = (options: SelectedCategory[], params: FilterOptionsState<SelectedCategory>) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const exists = options.some((o) => inputValue === o.name);
        if (inputValue && !exists) filtered.push({ inputValue, name: `Criar "${inputValue}"` });
        return filtered;
    };
    const getOptionLabel = (option: SelectedCategory | string) => {
        if (typeof option === 'string') return option;
        else if (option.inputValue) return option.inputValue;
        return option.name;
    };
    const renderSelectedStock = (id?: string) => {
        if (id && selectedStockId) return (
            <span style={{ display: "flex", gap: "1.5rem" }}>
                <InventoryIcon />
                {stocks?.find((s) => s.Id === Number(id))?.Name}
            </span>
        );
        else return null;
    };
    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        let isValid = true;
        if (!selectedStockId) isValid = false;
        if (!purchaseTypeId) isValid = false;

        if (isValid) {
            const data: WrappedItem = {
                Name: name,
                StockId: selectedStockId,
                Amount: amount,
                BuyingPrice: parseFloat(unitCost),
                SalePrice: parseFloat(salePrice),
                Purchase: {
                    Amount: amount,
                    PurchaseTypeId: purchaseTypeId,
                    TotalCost: calculateTotalCost(parseFloat(unitCost), amount, costVariablePercentage),
                    UnitCost: parseFloat(unitCost),
                    NetCost: parseFloat((parseFloat(unitCost) * amount).toFixed(2)),
                    CostVariablePercentage: costVariablePercentage,
                },
                isStockReposition: isStockReposition,
                Id: itemIdForReposition,
            };

            const existingCategory = categories?.find((c) => c.Name === selectedCategory?.name);
            const existingItem = purchase.Items.find((i) => i.Name === name);
            if (!isEditing && existingItem && existingItem.StockId === selectedStockId && existingItem.Purchase.PurchaseTypeId === purchaseTypeId) {
                InfoToast('Já existe um ítem igual a esse! Caso queira alterar um ítem, feche esse modal e selecione o respectivo ícone de edição!');
                setIsSubmitting(false);
                return;
            }

            if (existingCategory) data.CategoryId = existingCategory.Id;
            else data.Category = { Name: selectedCategory?.name };

            if (isEditing && purchaseItem) {
                data.uuid = purchaseItem.uuid;
                PurchaseWrapper.updateItem(data);
                SuccessToast('Ítem atualizado!');
                setIsSubmitting(false);
                onClose?.();
            }
            else {
                data.uuid = makeUUID();
                PurchaseWrapper.addItem(data);
                SuccessToast('Ítem adicionado a compra! Você pode adicionar mais ítens se quiser.');
                setIsSubmitting(false);
                onClose?.();
            }
        }
    };

    useEffect(() => {
        if (isEditing && purchaseItem) {
            setAmount(purchaseItem.Amount);
            setCostVariablePercentage(purchaseItem.Purchase.CostVariablePercentage!);
            setName(purchaseItem.Name!);
            setPurchaseTypeId(purchaseItem.Purchase.PurchaseTypeId!);
            setSalePrice(purchaseItem.SalePrice?.toFixed(2)!);
            setSelectedStockId(purchaseItem.StockId);
            setUnitCost(purchaseItem.BuyingPrice.toFixed(2));
            setSelectedCategory({ name: purchaseItem?.Category?.Name || categories?.find((c) => c.Id === purchaseItem.CategoryId)?.Name! });
            setIsStockReposition(purchaseItem.isStockReposition!);
            setItemIdForReposition(purchaseItem.Id);
        }
        else if (isStockReposition && itemForReposition) {
            const lastItemPurchase = itemForReposition.ItemPurchase[itemForReposition.ItemPurchase.length - 1];
            setPurchaseTypeId(lastItemPurchase.PurchaseTypeId);
            setCostVariablePercentage(lastItemPurchase.CostVariablePercentage);
            setSalePrice(Number(itemForReposition.SalePrice)?.toFixed(2)!);
            setSelectedStockId(itemForReposition.StockId);
            setSelectedCategory({ name: itemForReposition.Category.Name! });
            setName(itemForReposition.Name);
        }
    }, [isEditing, purchaseItem, categories, isStockReposition, itemForReposition]);

    return (
        <div className="create-purchase-form">
            <div className="basic-info">
                <h5>Informações básicas</h5>
                <FormControlLabel
                    label="Reposição de estoque?"
                    control={<Checkbox checked={isStockReposition} />}
                    onChange={(e, checked) => handleStockRepositionCheck(checked)}
                />
                <FormControl>
                    {isStockReposition && items?.length ?
                        <>
                            <InputLabel id="item-selection-label">Selecione um ítem para repor</InputLabel>
                            <Select
                                labelId="item-selection-label"
                                value={String(itemIdForReposition)}
                                label="Selecione um ítem para repor"
                                onChange={handleSelectedItemIdForReposition}
                                disabled={loadingItems}
                            >
                                {items?.map((item, i) => (
                                    <MenuItem key={i} value={item.Id}>
                                        {item.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                        :
                        <TextField
                            label="Nome do item"
                            value={name}
                            onChange={handleNameChange}
                            error={isSubmitting && !name}
                        />
                    }
                </FormControl>
                <FormControl>
                    <InputLabel id="purchase-type-label" disabled={!purchaseTypes?.length || loadingPurchaseTypes}>Tipo de compra</InputLabel>
                    <Select
                        label="Tipo de compra"
                        labelId="purchase-type-label"
                        disabled={!purchaseTypes?.length || loadingPurchaseTypes}
                        onChange={({ target }) => handlePurchaseTypeSelection(Number(target.value))}
                        error={isSubmitting && !purchaseTypeId}
                        value={String(purchaseTypeId)}
                        required
                    >
                        {purchaseTypes?.map((pt, i) => (
                            <MenuItem
                                key={i}
                                value={String(pt.Id)}
                            >
                                {friendlyPurchaseType(pt.Name)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <TextField
                        type="number"
                        label="Quantidade"
                        value={amount?.toString()}
                        onChange={handleAmountChange}
                        error={amount! <= 0}
                    />
                    {isStockReposition && itemForReposition &&
                        <FormHelperText>
                            Há {itemForReposition.Amount} unidade{itemForReposition.Amount > 1 ? 's' : ''} desse ítem em estoque no momento
                        </FormHelperText>
                    }
                </FormControl>
                <FormControl>
                    <NumberFormatBase
                        value={unitCost}
                        format={formatPrice}
                        onValueChange={(values) => handleUnitPriceChange(values.value)}
                        customInput={TextField}
                        label="Custo unitário"
                        placeholder="R$ 0,00"
                    />
                    {isStockReposition && itemForReposition &&
                        <FormHelperText>
                            Valor registrado na última compra: R${itemForReposition.BuyingPrice}
                        </FormHelperText>
                    }
                </FormControl>
                {purchaseTypeId === PURCHASE_TYPES.Profit &&
                    <FormControl>
                        <NumberFormatBase
                            value={salePrice}
                            format={formatPrice}
                            onValueChange={(values) => handleSalePriceChange(values.value)}
                            customInput={TextField}
                            label="Preço para venda"
                            placeholder="R$ 0,00"
                        />
                        {isStockReposition && itemForReposition &&
                            <FormHelperText>
                                {itemForReposition.SalePrice ?
                                    `Preço pra venda registrado atualmente: R$${itemForReposition.SalePrice}`
                                    :
                                    'Sem preço de venda registrado'
                                }
                            </FormHelperText>
                        }
                    </FormControl>
                }
                <FormControl>
                    <NumberFormatBase
                        value={costVariablePercentage}
                        format={formatCostVariablePercent}
                        onValueChange={(values) => handleCostVariablePercentChange(values.value)}
                        customInput={TextField}
                        label="Porcentagem de custo variável"
                        placeholder="0%"
                    />
                </FormControl>
            </div>
            <div className="purchase-stock">
                <h5>Estoque</h5>
                <FormControl>
                    <Select
                        renderValue={renderSelectedStock}
                        disabled={!stocks?.length || loadingStocks}
                        onChange={({ target }) => handleStockSelection(Number(target.value))}
                        error={isSubmitting && !selectedStockId}
                        value={String(selectedStockId)}
                        required
                    >
                        {stocks?.map((stock, i) => (
                            <MenuItem
                                key={i}
                                value={String(stock.Id)}
                                sx={{ display: "flex", gap: "1.5rem" }}
                            >
                                <InventoryIcon />
                                {stock.Name}
                            </MenuItem>
                        ))}
                    </Select>
                    {isSubmitting && !selectedStockId && <FormHelperText>Uma compra precisa estar associada a um estoque!</FormHelperText>}
                    {!stocks?.length && <FormHelperText sx={{ color: "orange" }}>Não há estoques disponíveis para seleção!</FormHelperText>}
                </FormControl>
            </div>
            <div className="item-category">
                <h5>Categoria</h5>
                <FormControl>
                    <Autocomplete
                        value={selectedCategory}
                        onChange={(e, value) => handleCategoryChange(value)}
                        filterOptions={filterCategoryOptions}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={mergedCategories()}
                        getOptionLabel={getOptionLabel}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => <TextField {...params} variant="outlined" color="primary" label="Categoria" />}
                        disabled={loadingCategories}
                    />
                    {isSubmitting && !selectedStockId && <FormHelperText>Uma compra precisa estar associada a um estoque!</FormHelperText>}
                    {!stocks?.length && <FormHelperText sx={{ color: "orange" }}>Não há estoques disponíveis para seleção!</FormHelperText>}
                </FormControl>
            </div>
            {unitCost && amount &&
                <div className="purchase-summary">
                    <h5>Resumo</h5>
                    <div className="purchase-summary-cost">
                        <span>
                            Custo líquido: <strong>R${(calculateNetCost(Number(unitCost), amount)).toFixed(2)}</strong>
                        </span>
                        <span>
                            Custo variável: <strong>R${(calculateVariableCost(Number(unitCost), amount, costVariablePercentage)).toFixed(2)}</strong>
                        </span>
                        <span>
                            Custo total: <strong>R${(calculateTotalCost(Number(unitCost), amount, costVariablePercentage)).toFixed(2)}</strong>
                        </span>
                    </div>
                </div>
            }
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={hasAnyError || !stocks?.length}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" size={24} />
                    :
                    isEditing ? 'Salvar' : 'Adicionar'
                }
            </Button>
        </div>
    );
}
