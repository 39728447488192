import { GridColDef } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { ItemPurchase } from "../types/item.types";


export const friendlyPurchaseType = (name: string) => {
    if (name === "Internal") return "Interno (para uso interno, não gera lucro)";
    else if (name === "Profit") return "Lucro (para venda, gera lucro)";
};

export const calculateNetCost = (unitCost: number, amount: number) => unitCost * amount;
export const calculateVariableCost = (unitCost: number, amount: number, costVariablePercent: number) => ((unitCost * amount) * (costVariablePercent / 100));
export const calculateTotalCost = (unitCost: number, amount: number, costVariablePercent: number) => {
    const variableCost = calculateVariableCost(unitCost, amount, costVariablePercent);
    const netCost = calculateNetCost(unitCost, amount);
    const totalCost = netCost + variableCost;
    return totalCost;
};
export const makeUUID = () => uuidv4();

export const makePurchaseItemsGridColumnsDefinition = () => {
    const columns: GridColDef<ItemPurchase>[] = [
        {
            field: "Name",
            headerName: "Nome",
            width: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Item.Name,
        },
        { field: "Amount", headerName: "Quantidade", width: 100, align: "center", headerAlign: "center", },
        {
            field: "PurchaseType",
            headerName: "Tipo de Compra",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => friendlyPurchaseType(params.row.PurchaseType.Name)?.replace(/\(([^)]+)\)/, ""),
        },
        {
            field: "Stock",
            headerName: "Estoque",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Item.Stock.Name,
        },
        {
            field: "Category",
            headerName: "Categoria",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Item.Category?.Name,
        },
        {
            field: "CostVariablePercentage",
            headerName: "Variável de Custo (%)",
            width: 180,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `${params.row.CostVariablePercentage}%`,
        },
        {
            field: "TotalCost",
            headerName: "Custo Total",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `R$${Number(params.row.TotalCost).toFixed(2)}`,
        },
    ];

    return columns;
};
