import { CreditCard, Paid, Pix } from "@mui/icons-material";


export const PAYMENT_METHODS = {
    money: {
        id: 1,
        friendlyName: "Dinheiro",
        icon: Paid,
    },
    credit: {
        id: 2,
        friendlyName: "Crédito",
        icon: CreditCard,
    },
    debit: {
        id: 3,
        friendlyName: "Débito",
        icon: CreditCard,
    },
    pix: {
        id: 4,
        friendlyName: "Pix",
        icon: Pix,
    },
};
