import React from "react";
import { Helmet } from "react-helmet";
import { GridColDef } from "@mui/x-data-grid";
import { Avatar, Button, IconButton, Tooltip } from "@mui/material";
import {
    AddBox,
    Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import AODataTable from "../../components/Table/DataTable";
import { usePurchases, useUsers } from "../../hooks";
import { CheckIfUserHasPermission } from "../../utils/access";
import UserWrapper from "../../wrappers/user.wrapper";
import {
    CREATE_PURCHASE_ALLOWED_ACCESSES,
    LIST_PURCHASES_ALLOWED_ACCESSES,
} from "../../constants/access.constant";
import BlankAvatar from "../../assets/png/blank-avatar.png";
import "./Purchases.styles.scss";


export default function Purchases() {
    const user = UserWrapper.get();
    const { loadingPurchases, purchases } = usePurchases();
    const { loadingUsers, users } = useUsers();
    const navigate = useNavigate();

    const isUserAllowedToCreate = CheckIfUserHasPermission(
        user.access!,
        CREATE_PURCHASE_ALLOWED_ACCESSES
    );
    const isUserAllowedToView = CheckIfUserHasPermission(
        user.access!,
        LIST_PURCHASES_ALLOWED_ACCESSES
    );
    const handleCreateButtonClick = () => navigate("/purchases/create");
    const handleViewButtonClick = (purchaseId: number) => navigate(`/purchases/${purchaseId}`);
    const columns: GridColDef[] = [
        { field: "Id", headerName: "ID", width: 70, align: "center", headerAlign: "center", },
        { field: "ItemAmount", headerName: "Qt. de Ítens", width: 100, align: "center", headerAlign: "center", },
        {
            field: "TotalValue",
            headerName: "Valor Total",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `R$ ${Number(params.row.TotalValue).toFixed(2)}`,
        },
        {
            field: "CreatedDate",
            headerName: "Data de Criação",
            width: 200,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => new Date(params.value).toLocaleString(),
        },
        {
            field: "UserId",
            headerName: "Criado por",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const user = users?.find((u) => u.Id === params.row.UserId);
                return (
                    <Tooltip arrow title={`${user?.FirstName} ${user?.LastName}`}>
                        <Avatar
                            alt="User picture"
                            src={user?.Picture || BlankAvatar}
                            sx={{ height: "2rem", width: "2rem" }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            field: "Actions",
            headerName: "Ações",
            width: 150,
            maxWidth: 300,
            align: "center",
            headerAlign: "center",
            colSpan: 4,
            renderCell: (params) => (
                <span>
                    <Tooltip
                        title={!isUserAllowedToView ? "Você não tem permissão pra fazer isso" : "Ver detalhes"}
                        arrow
                    >
                        <span>
                            <IconButton
                                disabled={!isUserAllowedToView}
                                onClick={(e) => handleViewButtonClick(params.row.Id)}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <>
            <Helmet title="Admin ONE | Compras" />
            <AODataTable
                useDefaultPreComponent
                columns={columns}
                rows={purchases!}
                isLoading={loadingPurchases || loadingUsers}
                title="Lista de Compras"
                preComponentAction={
                    <Tooltip
                        title={!isUserAllowedToCreate && "Você não tem permissão pra fazer isso"}
                        disableHoverListener={isUserAllowedToCreate}
                        arrow
                    >
                        <span>
                            <Button
                                startIcon={<AddBox />}
                                variant="contained"
                                sx={{ minWidth: "14rem", maxWidth: "16rem" }}
                                disabled={!isUserAllowedToCreate || loadingPurchases}
                                classes={{ disabled: "create-purchase-btn-disabled" }}
                                onClick={handleCreateButtonClick}
                            >
                                Nova Compra
                            </Button>
                        </span>
                    </Tooltip>
                }
            />
        </>
    );
}
