import React, { useEffect, useState } from "react";
import { Autocomplete, Button, CircularProgress, FilterOptionsState, FormControl, FormHelperText, MenuItem, Select, TextField, createFilterOptions } from "@mui/material";
import { NumberFormatBase } from "react-number-format";
import { Inventory as InventoryIcon } from "@mui/icons-material";
import { useCookies } from "react-cookie";

import { Item } from "../../../types/item.types";
import { useCategories, useStocks } from "../../../hooks";
import { ErrorToast, InfoToast } from "../../../utils/toaster";
import * as ItemService from "../../../services/item.service";
import "./ItemForm.styles.scss";


type ItemFormProps = {
    onClose?: (arg?: any) => void;
    item?: Item;
};

type SelectedCategory = {
    inputValue?: string;
    name: string;
};

const filter = createFilterOptions<SelectedCategory>();

export default function ItemForm({ item, onClose }: ItemFormProps) {
    const [name, setName] = useState("");
    const [amount, setAmount] = useState<number>();
    const [selectedStockId, setSelectedStockId] = useState<number>();
    const [selectedCategory, setSelectedCategory] = useState<SelectedCategory | null>(null);
    const [buyingPrice, setBuyingPrice] = useState("");
    const [salePrice, setSalePrice] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cookies] = useCookies(['session_token']);
    const { stocks, loadingStocks } = useStocks();
    const { categories, loadingCategories } = useCategories();
    const hasAnyError = !name || !amount || !selectedStockId;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setAmount(Number(e.target.value));
    const handleBuyingPriceChange = (value: string) => {
        const fixed = (parseFloat(value) / 100).toFixed(2);
        setBuyingPrice(fixed);
    };
    const handleSalePriceChange = (value: string) => {
        const fixed = (parseFloat(value) / 100).toFixed(2);
        setSalePrice(fixed);
    };
    const handleStockSelection = (id: number) => setSelectedStockId(id);
    const handleCategoryChange = (value: string | SelectedCategory | null) => {
        if (typeof value === "string") setSelectedCategory({ name: value });
        else if (value && value.inputValue) setSelectedCategory({ name: value.inputValue });
        else setSelectedCategory(value);
    };
    const filterCategoryOptions = (options: SelectedCategory[], params: FilterOptionsState<SelectedCategory>) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const exists = options.some((o) => inputValue === o.name);
        if (inputValue && !exists) filtered.push({ inputValue, name: `Criar "${inputValue}"` });
        return filtered;
    };
    const getOptionLabel = (option: SelectedCategory | string) => {
        if (typeof option === 'string') return option;
        else if (option.inputValue) return option.inputValue;
        return option.name;
    };
    const formatPrice = (value: string) => {
        if (!value) return '';
        const formatted = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2
        }).format(parseFloat(value) / 100);
        return formatted;
    };
    const renderSelectedStock = (id?: string) => {
        if (id && selectedStockId) return (
            <span style={{ display: "flex", gap: "1.5rem" }}>
                <InventoryIcon />
                {stocks?.find((s) => s.Id === Number(id))?.Name}
            </span>
        );
        else return null;
    };
    const makePatchData = () => {
        const patchData: Partial<Item> = {};
        if (name !== item?.Name) patchData.Name = name;
        if (selectedCategory?.name !== item?.Category?.Name) patchData.Category = { Name: selectedCategory?.name };
        if (amount !== item?.Amount) patchData.Amount = amount;
        if (selectedStockId !== item?.StockId) patchData.StockId = selectedStockId;
        if (Number(buyingPrice) !== item?.BuyingPrice) patchData.BuyingPrice = Number(buyingPrice);
        if (Number(salePrice) !== item?.SalePrice) patchData.SalePrice = Number(salePrice);
        return patchData;
    };
    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        let isValid = true;
        if (!name) isValid = false;
        if (amount !== 0 && !amount) isValid = false;
        if (!selectedStockId) isValid = false;

        if (isValid) {
            const data = makePatchData();
            if (!Object.entries(data).length) {
                InfoToast("Nada mudou! Antes de salvar, por favor altere alguma informação do ítem!");
                setIsSubmitting(false);
                return;
            }

            const existingCategory = categories?.find((c) => c.Name === selectedCategory?.name);
            if (existingCategory) {
                delete data.Category;
                data.CategoryId = existingCategory.Id;
            }

            if (item) {
                ItemService.UpdateItem(item?.Id, selectedStockId!, data, cookies.session_token)
                .then(() => onClose?.(true))
                .catch((err) => {
                    ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                })
                .finally(() => setIsSubmitting(false));
            }
        }
    };

    useEffect(() => {
        if (item) {
            setName(item.Name);
            setAmount(item.Amount);
            setBuyingPrice(Number(item.BuyingPrice).toFixed(2));
            if (item.SalePrice) setSalePrice(Number(item.SalePrice).toFixed(2));
            if (item?.Category?.Name) setSelectedCategory({ name: item.Category.Name });
            setSelectedStockId(item.StockId);
        }
    }, [item]);

    return (
        <div className="item-form">
            <div className="basic-info">
                <h5>Informações básicas</h5>
                <FormControl>
                    <TextField
                        label="Nome do item"
                        value={name}
                        onChange={handleNameChange}
                        error={isSubmitting && !name}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        type="number"
                        label="Quantidade"
                        value={amount?.toString()}
                        onChange={handleAmountChange}
                        error={amount! < 0}
                    />
                </FormControl>
                <FormControl>
                    <NumberFormatBase
                        value={buyingPrice}
                        format={formatPrice}
                        onValueChange={(values) => handleBuyingPriceChange(values.value)}
                        customInput={TextField}
                        label="Custo Unitário"
                        placeholder="R$ 0,00"
                    />
                </FormControl>
                <FormControl>
                    <NumberFormatBase
                        value={salePrice}
                        format={formatPrice}
                        onValueChange={(values) => handleSalePriceChange(values.value)}
                        customInput={TextField}
                        label="Preço para venda"
                        placeholder="R$ 0,00"
                    />
                </FormControl>
            </div>
            <div className="item-stock">
                <h5>Estoque</h5>
                <FormControl>
                    <Select
                        renderValue={renderSelectedStock}
                        disabled={!stocks?.length || loadingStocks}
                        onChange={({ target }) => handleStockSelection(Number(target.value))}
                        error={isSubmitting && !selectedStockId}
                        value={String(selectedStockId)}
                        required
                    >
                        {stocks?.map((stock, i) => (
                            <MenuItem
                                key={i}
                                value={String(stock.Id)}
                                sx={{ display: "flex", gap: "1.5rem" }}
                            >
                                <InventoryIcon />
                                {stock.Name}
                            </MenuItem>
                        ))}
                    </Select>
                    {isSubmitting && !selectedStockId && <FormHelperText>Uma compra precisa estar associada a um estoque!</FormHelperText>}
                    {!stocks?.length && <FormHelperText sx={{ color: "orange" }}>Não há estoques disponíveis para seleção!</FormHelperText>}
                </FormControl>
            </div>
            <div className="item-category">
                <h5>Categoria</h5>
                <FormControl>
                    <Autocomplete
                        value={selectedCategory}
                        onChange={(e, value) => handleCategoryChange(value)}
                        filterOptions={filterCategoryOptions}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={categories?.map((c) => ({ name: c.Name, } as SelectedCategory))!}
                        getOptionLabel={getOptionLabel}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => <TextField {...params} variant="outlined" color="primary" label="Categoria" />}
                        disabled={loadingCategories}
                    />
                </FormControl>
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={hasAnyError}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" size={24} />
                    :
                    'Salvar'
                }
            </Button>
        </div>
    );
}
