import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Avatar, Button, Checkbox, CircularProgress, FormControl, FormHelperText, InputLabel, ListItemText, ListSubheader, MenuItem, Select, TextField } from "@mui/material";

import { CreateDepartmentData, Department } from "../../../types/department.types";
import { BRANCHES } from "../../../constants/generic.constant";
import BlankAvatar from "../../../assets/png/blank-avatar.png";
import { CreateUserData, DepartmentProfile, User } from "../../../types/user.types";
import * as DepartmentService from "../../../services/department.service";
import * as UserService from "../../../services/user.service";
import { ErrorToast, InfoToast } from "../../../utils/toaster";
import { ACCESS_LEVELS } from "../../../constants/access.constant";
import "./DepartmentForm.styles.scss";


type DepartmentFormProps = {
    isEditing?: boolean;
    onClose?: (arg?: any) => void;
    department?: Department;
    users: User[];
};

type MemberAccessLevelForSelection = {
    level?: number;
    departmentId?: number;
    userId?: number;
    memberName?: string;
    memberPic?: string;
};

export default function DepartmentForm({ isEditing, onClose, department, users }: DepartmentFormProps) {
    const [name, setName] = useState({ error: false, helperTxt: "", value: "" });
    const [branch, setBranch] = useState({ error: false, helperTxt: "", value: String(BRANCHES.RJ) });
    const [members, setMembers] = useState<MemberAccessLevelForSelection[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cookies] = useCookies(["session_token"]);
    const hasAnyError = name.error || branch.error;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName({ error: false, helperTxt: "", value: e.target.value });
    const handleBranchChange = (value: string) => setBranch({ error: false, helperTxt: "", value });
    const handleMemberSelection = (member: MemberAccessLevelForSelection) => {
        if (members.find((m) => m.userId === member.userId && m.level === member.level)) {
            setMembers(prev => {
                const updated = [...prev];
                return updated.filter((up) => up.userId !== member.userId);
            });
        }
        else {
            setMembers(prev => {
                const updated = [...prev].filter((up) => up.userId !== member.userId);
                return [...updated, member];
            });
        }
    };

    const makePatchData = () => {
        const patchData: Partial<CreateDepartmentData & { Members?: Partial<DepartmentProfile>[] }> = {};
        if (name.value !== department?.Name) patchData.Name = name.value;
        if (Number(branch.value) !== department?.BranchId) patchData.BranchId = Number(branch.value);
        if (
            !!members.filter((m) => !users.some((u) => u.DepartmentUserProfile.some((dup) => dup.DepartmentId === m.departmentId && m.userId === dup.UserId))).length
            ||
            members.length !== users.filter((u) => u.DepartmentUserProfile.some((dup) => dup.DepartmentId === department?.Id)).length
        ) {
            patchData.Members = members.map((mal) => (
                {
                    AccessLevelId: mal.level,
                    DepartmentId: mal.departmentId
                }
            ));
        }
        console.log("Patch data:", patchData);
        return patchData;
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        let isValid = true;
        if (!name.value) {
            isValid = false;
            setName({ ...name, error: true, helperTxt: "Esse campo é obrigatório!" });
        }

        if (isValid) {
            const data: CreateDepartmentData = {
                Name: name.value,
                BranchId: Number(branch.value),
            };
            setIsSubmitting(true);
            if (isEditing && department) {
                const patchData = makePatchData();

                if (!Object.entries(patchData).length) {
                    InfoToast("Nada mudou! Antes de salvar, por favor altere alguma informação do departamento!");
                    setIsSubmitting(false);
                }
                else {
                    DepartmentService.UpdateDepartment(department.Id, cookies.session_token, patchData)
                    .then(async (data) => {
                        let membersUpdated = true;
                        const matchingUsers = users.filter((u) => u.DepartmentUserProfile.some((dup) => dup.DepartmentId === department.Id));
                        const usersToRemove = matchingUsers.filter((mu) => !members.some((m) => m.userId === mu.Id));
                        for (const member of members) {
                            const user = users?.find((u) => u.Id === member.userId);
                            const diffDepartments = user?.DepartmentUserProfile?.filter((dup) => dup.DepartmentId !== department.Id);
                            const data = {
                                DepartmentUserProfile: [
                                    ...diffDepartments!,
                                    {
                                        AccessLevelId: member.level,
                                        DepartmentId: department.Id,
                                        UserId: member.userId,
                                    } as DepartmentProfile
                                ]
                            }
                            try {
                                await UserService.UpdateUser(member.userId!, cookies.session_token, data);
                            } catch (error) {
                                membersUpdated = false;
                            }
                        }
                        for (const user of usersToRemove) {
                            const data: Partial<CreateUserData> = {
                                DepartmentUserProfile: user.DepartmentUserProfile.filter((dup) => dup.DepartmentId !== department.Id)
                            };
                            try {
                                await UserService.UpdateUser(user.Id, cookies.session_token, data);
                            } catch (err) {
                                membersUpdated = false;
                            }
                        }
                        if (!membersUpdated) InfoToast('Não foi possível adicionar os membros! Tente editar o departamento ou os usuários!');
                        onClose?.(true);
                    })
                    .catch((err) => {
                        if (err?.response?.status === 404) {
                            InfoToast('O departamento que você está tentando editar não existe! Por favor atualize a página!');
                        }
                        else {
                            ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                        }
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
                }
            }
            else {
                DepartmentService.CreateDepartment(cookies.session_token, data)
                .then(async (dep) => {
                    let membersAdded = true;
                    for (const member of members) {
                        const user = users?.find((u) => u.Id === member.userId);
                        const diffDepartments = user?.DepartmentUserProfile?.filter((dup) => dup.DepartmentId !== dep.Id);
                        const data = {
                            DepartmentUserProfile: [
                                ...diffDepartments!,
                                {
                                    AccessLevelId: member.level,
                                    DepartmentId: dep.Id,
                                    UserId: member.userId,
                                } as DepartmentProfile
                            ]
                        }
                        try {
                            await UserService.UpdateUser(member.userId!, cookies.session_token, data);
                        } catch (error) {
                            membersAdded = false;
                        }
                    }
                    if (!membersAdded) InfoToast('Não foi possível adicionar os membros! Tente editar o departamento ou os usuários!');
                    onClose?.(true);
                })
                .catch((err) => {
                    if (err?.response?.status === 409) {
                        ErrorToast('Já existe um departamento parecido com esse!');
                    }
                    else {
                        ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
            }
        }
    };

    const renderSelectedMembers = (selected: MemberAccessLevelForSelection[]) => (
        <span style={{ display: "flex", gap: "1.5rem" }}>
            {selected.map((s, i) => (
                <Avatar key={i} src={s.memberPic || BlankAvatar} />
            ))}
        </span>
    );

    useEffect(() => {
        if (isEditing && department) {
            setName((prev) => ({ ...prev, value: department.Name }));
            setBranch((prev) => ({ ...prev, value: String(department.BranchId) }));
            const matchUsers = users.filter((u) => u.DepartmentUserProfile.some((dup) => dup.DepartmentId === department.Id));
            const mals = matchUsers.map((u) => {
                const dup = u.DepartmentUserProfile.find((dup) => dup.DepartmentId === department.Id);
                const mal: MemberAccessLevelForSelection = {
                    departmentId: dup?.DepartmentId,
                    level: dup?.AccessLevelId,
                    memberName: `${u.FirstName} ${u.LastName}`,
                    memberPic: u.Picture,
                    userId: u.Id,
                };
                return mal;
            });
            setMembers(mals);
        }
    }, [department, isEditing, users]);

    return (
        <div className="create-department-form">
            <div className="basic-info">
                <h5>Informações Básicas</h5>
                <TextField
                    variant="outlined"
                    name="name"
                    label="Nome"
                    value={name.value}
                    onChange={handleNameChange}
                    error={name.error}
                    helperText={name.helperTxt}
                    fullWidth
                    required
                />
                <FormControl>
                    <InputLabel id="branch-label" required error={branch.error}>Filial</InputLabel>
                    <Select
                        labelId="branch-label"
                        id="branch"
                        label="Filial"
                        onChange={(e) => handleBranchChange(e.target.value)}
                        value={branch.value}
                        error={branch.error}
                        required
                    >
                        <MenuItem value={String(BRANCHES.RJ)}>ONE RJ</MenuItem>
                    </Select>
                    {branch.error && branch.helperTxt && <FormHelperText>{branch.helperTxt}</FormHelperText>}
                </FormControl>
            </div>
            <div className="members">
                <h5>Membros</h5>
                <FormControl>
                    <InputLabel id="members-label" disabled={!users}>Membros do departmento</InputLabel>
                    <Select
                        labelId="members-label"
                        id="members"
                        label="Membros do departamento"
                        renderValue={renderSelectedMembers}
                        value={members}
                        disabled={!users?.length}
                        multiple
                    >
                        {users?.map((user, i) => (
                            <span key={i}>
                                <ListSubheader>
                                    <span style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                                        <Avatar
                                            alt="User picture"
                                            src={user.Picture || BlankAvatar}
                                            sx={{ height: "2rem", width: "2rem" }}
                                        />
                                        <span>{`${user.FirstName} ${user.LastName}`}</span>
                                    </span>
                                </ListSubheader>
                                {Object.values(ACCESS_LEVELS).slice(0, 3).map((al, i) => (
                                    <MenuItem
                                        onClick={(e) => handleMemberSelection({
                                            departmentId: department?.Id,
                                            level: Number(al.id),
                                            userId: user.Id,
                                            memberName: `${user.FirstName} ${user.LastName}`,
                                            memberPic: user.Picture,
                                        })}
                                        key={i}
                                    >
                                            <Checkbox
                                                checked={
                                                    (!isEditing && !!members.find((m) => m.userId === user.Id && m.level === Number(al.id)))
                                                    ||
                                                    (isEditing && department && !!members.find((m) => m.userId === user.Id && m.departmentId === department.Id && m.level === Number(al.id)))
                                                }
                                            />
                                            <ListItemText primary={al.friendlyName} />
                                    </MenuItem>
                                ))}
                            </span>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={hasAnyError || isSubmitting}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" size={24} />
                    :
                    isEditing ? 'Salvar' : 'Criar'
                }
            </Button>
        </div>
    );
}
