import { useCookies } from "react-cookie";
import { useQuery } from "@tanstack/react-query";
import { ValidateSessionToken } from "@one-tech/access-control-lib";

import { InfoToast } from "../utils/toaster";
import UserWrapper from "../wrappers/user.wrapper";
import * as AccessUtils from "../utils/access";
import {
    ACCESS_LEVELS,
    ALL_COFFEE_SHOP_ACCESSES,
    ALL_SYSTEM_FINANCE_ACCESSES,
    ALL_SYSTEM_MANAGING_ACCESSES,
    LIST_DEPARTMENTS_ALLOWED_ACCESSES,
    LIST_ITEMS_ALLOWED_ACCESSES,
    LIST_STOCKS_ALLOWED_ACCESSES,
    LIST_STOCK_ITEMS_ALLOWED_ACCESSES,
    LIST_USERS_ALLOWED_ACCESSES,
    PRODUCTS_ACTIONS_ALLOWED_ACCESSES,
    VIEW_DEPARTMENT_ALLOWED_ACCESSES
} from "../constants/access.constant";
import * as UserService from "../services/user.service";
import * as StockService from "../services/stock.service";
import * as DepartmentService from "../services/department.service";
import * as PurchaseService from "../services/purchase.service";
import * as ItemService from "../services/item.service";
import * as SalesService from "../services/sales.service";
import * as ProductService from "../services/product.service";


export const useSession = () => {
    const [cookies, , removeCookie] = useCookies(['session_token']);
    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['session'],
        queryFn: () => ValidateSessionToken(cookies.session_token),
        onError: (err: any) => {
            if (err?.message?.includes("JWTExpired")) {
                InfoToast('Parece que sua sessão expirou! Faça login novamente 🔌');
                removeCookie('session_token');
                UserWrapper.clear();
            }
            else console.error(err);
        },
        onSuccess: (session) => UserWrapper.set({ session }),
        enabled: !!cookies.session_token,
        notifyOnChangeProps: ['data', 'isStale'],
    });

    return {
        session: data,
        loadingSession: isLoading && fetchStatus !== "idle",
    };
}

export const useLoggedUser = () => {
    const user = UserWrapper.get();
    const userId = user?.data?.Id || user.session.decoded?.userId;
    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['user', userId],
        queryFn: () => UserService.GetUserById(userId!),
        enabled: user && user.session.auth,
        notifyOnChangeProps: ['data', 'status'],
        onSuccess: (data) => {
            const access = AccessUtils.MakeUserAccessObject(data!);
            const isCoffeeShopStaff = AccessUtils.isCoffeeShopStaff(access);
            UserWrapper.set({ access, session: user.session, data: data!, isCoffeeShopStaff, });
        },
    });

    return {
        user: data,
        loadingUser: isLoading && fetchStatus !== "idle",
    };
};

export const useStocks = () => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const isUserAllowedToListStocks = AccessUtils.CheckIfUserHasPermission(
        user.access!,
        LIST_STOCKS_ALLOWED_ACCESSES,
    );

    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['stocks'],
        queryFn: () => StockService.ListStocks(cookies.session_token),
        enabled: !!cookies.session_token && user.session.auth && isUserAllowedToListStocks,
        notifyOnChangeProps: ['data'],
    });

    return {
        stocks: data,
        loadingStocks: isLoading && fetchStatus !== "idle",
    };
};

export const useDepartments = () => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const isUserAllowedToListDepartments = AccessUtils.CheckIfUserHasPermission(
        user.access!,
        [...LIST_DEPARTMENTS_ALLOWED_ACCESSES, ...ALL_COFFEE_SHOP_ACCESSES],
    );

    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['departments'],
        queryFn: () => DepartmentService.ListDepartments(cookies.session_token),
        enabled: user.session.auth && isUserAllowedToListDepartments,
        notifyOnChangeProps: ['data'],
    });

    return {
        departments: data,
        loadingDepartments: isLoading && fetchStatus !== "idle",
    };
};

export const useDepartment = (id: number) => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const isUserAllowedToReadDepartment = AccessUtils.CheckIfUserHasPermission(
        user.access!,
        VIEW_DEPARTMENT_ALLOWED_ACCESSES(id),
    );

    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['departments', id],
        queryFn: () => DepartmentService.GetDepartmentById(id, cookies.session_token),
        enabled: user.session.auth && isUserAllowedToReadDepartment && !!id,
        notifyOnChangeProps: ['data'],
    });

    return {
        department: data,
        loadingDepartment: isLoading && fetchStatus !== "idle",
    };
};

export const useUsers = () => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const isUserAllowedToListUsers = AccessUtils.CheckIfUserHasPermission(
        user.access!,
        [
            ...LIST_USERS_ALLOWED_ACCESSES,
            ...ALL_COFFEE_SHOP_ACCESSES,
        ],
    );

    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['users'],
        queryFn: () => UserService.ListUsers(cookies.session_token),
        enabled: user.session.auth && isUserAllowedToListUsers,
        notifyOnChangeProps: ['data'],
    });

    return {
        users: data,
        loadingUsers: isLoading && fetchStatus !== "idle",
    };
};

export const usePurchases = () => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const isUserAllowedToListPurchases = AccessUtils.CheckIfUserHasPermission(
        user.access!,
        [
            ...ALL_SYSTEM_MANAGING_ACCESSES,
            ...ALL_SYSTEM_FINANCE_ACCESSES,
        ]
    );

    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['purchases'],
        queryFn: () => PurchaseService.ListPurchases(cookies.session_token),
        enabled: user.session.auth && isUserAllowedToListPurchases,
        notifyOnChangeProps: ['data'],
    });

    return {
        purchases: data,
        loadingPurchases: isLoading && fetchStatus !== "idle",
    };
};

export const usePurchaseTypes = () => {
    const user = UserWrapper.get();
    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['purchaseTypes'],
        queryFn: () => PurchaseService.ListPurchaseTypes(),
        enabled: user.session.auth,
        notifyOnChangeProps: ['data'],
    });

    return {
        purchaseTypes: data,
        loadingPurchaseTypes: isLoading && fetchStatus !== "idle",
    };
}

export const useItems = () => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const isUserAllowedToListItems = AccessUtils.CheckIfUserHasPermission(
        user.access!,
        LIST_ITEMS_ALLOWED_ACCESSES,
    );

    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['items'],
        queryFn: () => ItemService.ListItems(cookies.session_token),
        enabled: user.session.auth && isUserAllowedToListItems,
        notifyOnChangeProps: ['data'],
    });

    return {
        items: data,
        loadingItems: isLoading && fetchStatus !== "idle",
    };
};

export const useProducts = () => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const isUserAllowedToListProducts = AccessUtils.CheckIfUserHasPermission(
        user.access!,
        PRODUCTS_ACTIONS_ALLOWED_ACCESSES
    );

    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['products'],
        queryFn: () => ProductService.ListProducts(cookies.session_token),
        enabled: user.session.auth && isUserAllowedToListProducts,
        notifyOnChangeProps: ['data'],
    });

    return {
        products: data,
        loadingProducts: isLoading && fetchStatus !== "idle",
    };
};

export const useStockItems = (stockId: number) => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const isUserAllowedToListStockItems = AccessUtils.CheckIfUserHasPermission(
        user.access!,
        LIST_STOCK_ITEMS_ALLOWED_ACCESSES(stockId),
    );

    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['stockItems', stockId],
        queryFn: () => ItemService.ListStockItems(stockId, cookies.session_token),
        enabled: user.session.auth && isUserAllowedToListStockItems,
        notifyOnChangeProps: ['data'],
    });

    return {
        stockItems: data,
        loadingItems: isLoading && fetchStatus !== "idle",
    };
};

export const useCategories = () => {
    const user = UserWrapper.get();
    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['categories'],
        queryFn: () => ItemService.ListCategories(),
        enabled: user.session.auth,
        notifyOnChangeProps: ['data'],
    });

    return {
        categories: data,
        loadingCategories: isLoading && fetchStatus !== "idle",
    };
};

export const useSales = () => {
    const [cookies] = useCookies(['session_token']);
    const user = UserWrapper.get();
    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['sales'],
        queryFn: () => SalesService.ListSales(cookies.session_token),
        enabled: user.session.auth,
        notifyOnChangeProps: ['data'],
    });

    return {
        sales: data,
        loadingSales: isLoading && fetchStatus !== "idle",
    };
};

export const useCoffeeShopStaff = () => {
    const { users, loadingUsers } = useUsers();
    const staff = users?.filter((u) =>
        u.SystemUserProfile.find((sup) =>
            sup.AccessLevelId === ACCESS_LEVELS.coffee_shop_manager.id
            ||
            sup.AccessLevelId === ACCESS_LEVELS.coffee_shop_worker.id
        )
    );
    return {
        staff,
        loadingStaff: loadingUsers,
    };
};

export const usePaymentMethods = () => {
    const user = UserWrapper.get();
    const { data, fetchStatus, isLoading } = useQuery({
        queryKey: ['paymentMethods'],
        queryFn: () => SalesService.ListPaymentMethods(),
        enabled: user.session.auth,
        notifyOnChangeProps: ['data'],
    });
    return {
        paymentMethods: data,
        loadingPaymentMethods: isLoading && fetchStatus !== "idle",
    };
};
