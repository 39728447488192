import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { Avatar, Tooltip } from "@mui/material";

import AODataTable from "../../components/Table/DataTable";
import { makePurchaseItemsGridColumnsDefinition } from "../../utils/purchase";
import { useUsers } from "../../hooks";
import { Purchase } from "../../types/purchase.types";
import * as PurchaseService from "../../services/purchase.service";
import BlankAvatar from "../../assets/png/blank-avatar.png";


export default function DetailedPurchase() {
    const [purchase, setPurchase] = useState<Purchase>();
    const [isLoading, setIsLoading] = useState(true);
    const [cookies] = useCookies(['session_token']);
    const { loadingUsers, users } = useUsers();
    const columns = makePurchaseItemsGridColumnsDefinition();
    const purchaseOwner = users?.find((u) => u.Id === purchase?.UserId);
    const { id } = useParams();
    const purchaseId = Number(id);

    useEffect(() => {
        if (purchaseId) {
            PurchaseService.GetPurchase(purchaseId, cookies.session_token).then((data) => {
                setPurchase(data);
                setIsLoading(false);
            });
        }
    }, [purchaseId, cookies.session_token]);

    return (
        <>
            <Helmet title="Admin ONE | Compra Detalhada" />
            <AODataTable
                useDefaultPreComponent
                columns={columns}
                rows={purchase?.ItemPurchase!}
                preComponentAction={
                    !isLoading ? (
                        <span style={{ display: "flex", gap: "1rem", alignItems: "center", color: "#fff" }}>
                            <span>
                                Valor total: <strong>R${Number(purchase?.TotalValue).toFixed(2)}</strong>
                            </span>
                            <span>-</span>
                            <span>Criada por:</span>
                            <span>
                                <Tooltip
                                    arrow
                                    title={`${purchaseOwner?.FirstName} ${purchaseOwner?.LastName}`}
                                >
                                    <Avatar src={purchaseOwner?.Picture || BlankAvatar} />
                                </Tooltip>
                            </span>
                        </span>
                    )
                    :
                    <></>
                }
                isLoading={loadingUsers || isLoading}
                title={isLoading ? "Carregando..." : "Compra detalhada"}
            />
        </>
    );
}
