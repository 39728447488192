import React, { useState } from "react";
import { Avatar, Button, CssBaseline, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet";
import { useQueryClient } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { AddBox, DeleteForever as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";

import { coffeeShopTheme } from "../../../themes";
import UserWrapper from "../../../wrappers/user.wrapper";
import { CheckIfUserHasPermission } from "../../../utils/access";
import { COFFEE_SHOP_MANAGEMENT_ALLOWED_ACCESSES } from "../../../constants/access.constant";
import { User } from "../../../types/user.types";
import BlankAvatar from "../../../assets/png/blank-avatar.png";
import { PromiseToast, SuccessToast } from "../../../utils/toaster";
import * as UserService from "../../../services/user.service";
import AODataTable from "../../../components/Table/DataTable";
import UserForm from "../../../components/Forms/UserForm";
import GenericDialog from "../../../components/Dialogs/Generic";
import { useCoffeeShopStaff } from "../../../hooks";


export default function CoffeeShopStaff() {
    const [showUserFormDialog, setShowUserFormDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [userToEdit, setUserToEdit] = useState<User>();
    const [userToDelete, setUserToDelete] = useState<User>();
    const [cookies] = useCookies(["session_token"]);
    const user = UserWrapper.get();
    const queryClient = useQueryClient();
    const { loadingStaff, staff } = useCoffeeShopStaff();
    const isUserAllowedToAct = CheckIfUserHasPermission(
        user.access!,
        COFFEE_SHOP_MANAGEMENT_ALLOWED_ACCESSES
    );

    const columns: GridColDef<User>[] = [
        {
            field: "Picture",
            headerName: "Foto",
            width: 70,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => <Avatar alt="User picture" src={params.value || BlankAvatar} sx={{ height: "2rem", width: "2rem" }} />
        },
        { field: "FirstName", headerName: "Nome", width: 200, align: "center", headerAlign: "center", },
        { field: "LastName", headerName: "Sobrenome", width: 200, align: "center", headerAlign: "center", },
        { field: "Email", headerName: "E-mail", width: 300, maxWidth: 500, align: "center", headerAlign: "center", },
        {
            field: "Actions",
            headerName: "Ações",
            width: 150,
            maxWidth: 300,
            align: "center",
            headerAlign: "center",
            colSpan: 4,
            renderCell: (params) => (
                <span>
                    <Tooltip
                        title={!isUserAllowedToAct && "Você não tem permissão pra fazer isso"}
                        disableHoverListener={isUserAllowedToAct}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToAct} onClick={(e) => handleEditButtonClick(params.row)}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={
                            !isUserAllowedToAct ?
                                "Você não tem permissão pra fazer isso" :
                               user.data?.Email === params.row.Email && "Você não pode se deletar!"
                        }
                        disableHoverListener={isUserAllowedToAct && user.data?.Email !== params.row.Email}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToAct || user.data?.Email === params.row.Email} onClick={(e) => { toggleDeleteDialog(params.row); }}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </span>
            ),
        },
    ];

    const handleEditButtonClick = (u: User) => {
        setIsEditing(true);
        setUserToEdit(u);
        toggleUserFormDialog();
    };
    const handleCreateButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setIsEditing(false);
        toggleUserFormDialog();
    };
    const toggleUserFormDialog = (submitted?: boolean) => {
        if (typeof submitted === "boolean" && submitted) {
            queryClient.invalidateQueries(['users']).then(() => SuccessToast(`Usuário ${isEditing ? 'atualizado' : 'criado'}!`));
        }
        setShowUserFormDialog(!showUserFormDialog);
    };
    const toggleDeleteDialog = (clickedUser?: User) => {
        if (clickedUser) setUserToDelete(clickedUser);
        setShowDeleteDialog(!showDeleteDialog);
    };
    const deleteUser = async () => {
        if (userToDelete) {
            toggleDeleteDialog();
            await PromiseToast(
                UserService.DeleteUser(userToDelete.Id, cookies.session_token),
                {
                    error: "Não conseguimos deletar esse usuário! Tente novamente!",
                    pending: "Deletando usuário...",
                    success: "Usuário deletado!"
                }
            );
            await queryClient.invalidateQueries(['users']);
        }
    };

    return (
        <ThemeProvider theme={coffeeShopTheme}>
            <CssBaseline />
            <Helmet title="Coffee Shop | Staff" />
            <GenericDialog
                onClose={toggleDeleteDialog}
                open={showDeleteDialog}
                title="Deletar Usuário"
                useConfirmAndCancelButtons
                onConfirm={deleteUser}
            >
                <p style={{ fontSize: "1.2rem" }}>Tem certeza que deseja deletar <strong>{userToDelete?.FirstName}</strong>?</p>
            </GenericDialog>
            <GenericDialog
                onClose={toggleUserFormDialog}
                open={showUserFormDialog}
                title={`${isEditing ? 'Editar' : 'Adicionar'} Usuário`}
            >
                <UserForm
                    isEditing={isEditing}
                    onClose={toggleUserFormDialog}
                    user={userToEdit}
                    isCoffeeShop
                />
            </GenericDialog>
            <AODataTable
                useDefaultPreComponent
                columns={columns}
                rows={staff!}
                isLoading={loadingStaff}
                title="Staff do Coffee Shop"
                preComponentAction={
                    <Tooltip
                        title={!isUserAllowedToAct && "Você não tem permissão pra fazer isso"}
                        disableHoverListener={isUserAllowedToAct}
                        arrow
                    >
                        <span>
                            <Button
                                startIcon={<AddBox />}
                                variant="contained"
                                sx={{ minWidth: "14rem", maxWidth: "16rem" }}
                                disabled={!isUserAllowedToAct}
                                classes={{ disabled: "create-user-btn-disabled" }}
                                onClick={handleCreateButtonClick}
                            >
                                Adicionar Staff
                            </Button>
                        </span>
                    </Tooltip>
                }
            />
        </ThemeProvider>
    );
}
