import { WrappedItem } from "../types/item.types";
import { WrappedPurchase } from "../types/purchase.types";


const PurchaseWrapper = (() => {
    const defaultPurchase: WrappedPurchase = {
        Items: [],
    };

    const get = (): WrappedPurchase => {
        const purchase = window.sessionStorage.getItem('purchase');
        if (purchase && purchase !== "undefined") return JSON.parse(purchase);
        else return defaultPurchase;
    };

    const set = (purchase: WrappedPurchase) => {
        window.sessionStorage.setItem('purchase', JSON.stringify(purchase));
    };

    const addItem = (item: WrappedItem) => {
        const purchase = get();
        const items = [...purchase.Items];
        items.push(item);
        purchase.Items = items;
        set(purchase);
    };

    const removeItem = (uuid: string) => {
        const purchase = get();
        const filtered = purchase.Items.filter((i) => i.uuid !== uuid);
        purchase.Items = filtered;
        set(purchase);
    };

    const updateItem = (item: WrappedItem) => {
        removeItem(item.uuid!);
        addItem(item);
    };

    const clear = () => window.sessionStorage.removeItem('purchase');

    return {
        addItem,
        clear,
        get,
        removeItem,
        set,
        updateItem,
    };
})();

export default PurchaseWrapper;
