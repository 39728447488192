import { createTheme } from "@mui/material";
import { blueGrey, brown } from "@mui/material/colors";


export const defaultTheme = createTheme({
    palette: {
        primary: {
            main: blueGrey[500],
            light: blueGrey[200],
            dark: blueGrey[700],
        },
        background: {
            default: blueGrey[300],
        },
    },
});

export const coffeeShopTheme = createTheme({
    palette: {
        primary: {
            main: brown[500],
            light: brown[200],
            dark: brown[700],
        },
        secondary: {
            main: brown[900],
        },
        background: {
            default: brown[400],
        },
    },
});
