export const connections = {
    userApiUrl: process.env.REACT_APP_USER_API_URL,
    stockApiUrl: process.env.REACT_APP_STOCK_API_URL,
    departmentApiUrl: process.env.REACT_APP_DEPARTMENT_API_URL,
    purchaseApiUrl: process.env.REACT_APP_PURCHASE_API_URL,
    itemApiUrl: process.env.REACT_APP_ITEM_API_URL,
    salesApiUrl: process.env.REACT_APP_SALES_API_URL,
}

export const google = {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    oauthApi: 'https://www.googleapis.com/oauth2',
}

export const coffeeShopDepartmentId = Number(process.env.REACT_APP_COFFEE_SHOP_DEPARTMENT_ID);
export const appEnv = process.env.REACT_APP_ENV || 'dev';
