import React, { useState } from "react";
import { AppBar, Avatar, Button, Container, IconButton, Menu, MenuItem, ThemeProvider, Toolbar, Tooltip, Typography } from "@mui/material";
import { AlbumOutlined, Coffee, Menu as MenuIcon } from "@mui/icons-material"
import { Box } from "@mui/system";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import { Pages } from "../../../routes";
import { InfoToast } from "../../../utils/toaster";
import UserWrapper from "../../../wrappers/user.wrapper";
import BlankAvatarPic from "../../../assets/png/blank-avatar.png";
import { CheckIfUserHasPermission } from "../../../utils/access";
import { coffeeShopTheme, defaultTheme } from "../../../themes";


export default function Header() {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [cookies, , removeCookie] = useCookies(['session_token']);
    const navigate = useNavigate();
    const location = useLocation();
    const user = UserWrapper.get();
    const isCoffeeShop = location.pathname.includes("/coffee-shop");

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
    const handleCloseNavMenu = (event: React.MouseEvent<HTMLElement>, path?: string) => {
        if (path) navigate(path);
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => setAnchorElUser(null);
    const handleLogout = () => {
        handleCloseUserMenu();
        if (cookies.session_token) removeCookie("session_token");
        UserWrapper.clear();
        navigate("/");
        InfoToast("Tchau, até a próxima!");
    };

    return (
        <ThemeProvider theme={isCoffeeShop ? coffeeShopTheme : defaultTheme}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* Responsive Header begins */}
                        {isCoffeeShop ?
                            <Coffee sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                            :
                            <AlbumOutlined sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        }
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            {isCoffeeShop ? "Coffee Shop" : "Admin ONE"}
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                                }}
                                open={!!anchorElNav}
                                onClose={handleCloseNavMenu}
                                sx={{
                                display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {Pages.map((page, i) => (
                                    <MenuItem key={i} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        {/* Responsive Header ends */}
                        <AlbumOutlined sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                        >
                            {isCoffeeShop ? "Coffee Shop" : "Admin ONE"}
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {Pages.map((page, i) => (
                                !page.requireLogin
                                ||
                                (page.requireLogin && page.headerLink && user.session.auth && CheckIfUserHasPermission(user.access!, page.allowedAccesses)) ?
                                    <Button
                                        key={i}
                                        onClick={(e) => handleCloseNavMenu(e, page.path)}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page.name}
                                    </Button>
                                    :
                                    null
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="User picture" src={user.session.auth ? user.google?.picture : BlankAvatarPic} />
                                </IconButton>
                            </Tooltip>
                            {user.session.auth &&
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={handleLogout}>
                                        <Typography textAlign="center">Sair</Typography>
                                    </MenuItem>
                                </Menu>
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}
