import { GridColDef } from "@mui/x-data-grid";
import { Item } from "../types/item.types";

export const makeItemsGridColumnDefinition = () => {
    const columns: GridColDef<Item>[] = [
        {
            field: "Name",
            headerName: "Nome",
            width: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Name,
        },
        { field: "Amount", headerName: "Quantidade", width: 100, align: "center", headerAlign: "center", },
        {
            field: "Category",
            headerName: "Categoria",
            width: 180,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Category?.Name,
        },
        {
            field: "BuyingPrice",
            headerName: "Preço de Compra",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `R$${Number(params.row.BuyingPrice).toFixed(2)}`,
        },
        {
            field: "SalePrice",
            headerName: "Preço de Venda",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row?.SalePrice ? `R$${Number(params.row?.SalePrice).toFixed(2)}` : '-',
        },
        {
            field: "CreatedDate",
            headerName: "Criado em",
            width: 160,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => new Date(params.value).toLocaleString(),
        },
        {
            field: "UpdatedDate",
            headerName: "Atualizado em",
            width: 160,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => params.value ? new Date(params.value).toLocaleString() : '-',
        },
    ];
    return columns;
};
