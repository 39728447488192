import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { Avatar, CssBaseline, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import { Visibility } from "@mui/icons-material";

import UserWrapper from "../../wrappers/user.wrapper";
import { useSales, useUsers } from "../../hooks";
import { CheckIfUserHasPermission } from "../../utils/access";
import { ALL_COFFEE_SHOP_ACCESSES, LIST_SALES_ALLOWED_ACCESSES } from "../../constants/access.constant";
import BlankAvatar from "../../assets/png/blank-avatar.png";
import { Helmet } from "react-helmet";
import AODataTable from "../../components/Table/DataTable";
import { Sale } from "../../types/sales.type";
import { coffeeShopTheme, defaultTheme } from "../../themes";
import "./Sales.styles.scss";


export default function Sales() {
    const user = UserWrapper.get();
    const { loadingSales, sales } = useSales();
    const { loadingUsers, users } = useUsers();
    const navigate = useNavigate();
    const location = useLocation();
    const isCoffeeShop = location.pathname.includes("/coffee-shop");

    const isUserAllowedToView = CheckIfUserHasPermission(
        user.access!,
        [...LIST_SALES_ALLOWED_ACCESSES, ...ALL_COFFEE_SHOP_ACCESSES]
    );

    const handleViewButtonClick = (saleId: number) => navigate(`${isCoffeeShop ? '/coffee-shop' : ''}/sales/${saleId}`);
    const columns: GridColDef<Sale>[] = [
        { field: "ItemAmount", headerName: "Qt. de Ítens", width: 100, align: "center", headerAlign: "center", },
        {
            field: "Department",
            headerName: "Departamento",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Department?.Name || '-',
        },
        {
            field: "PaymentMethodId",
            headerName: "Método de Pagamento",
            width: 180,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.PaymentMethod?.Name,
        },
        {
            field: "TotalValue",
            headerName: "Receita",
            width: 180,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `R$${Number(params.row.TotalValue).toFixed(2)}`,
        },
        {
            field: "CreatedDate",
            headerName: "Data de Criação",
            width: 180,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => new Date(params.value).toLocaleString(),
        },
        {
            field: "UserId",
            headerName: "Criada por",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const user = users?.find((u) => u.Id === params.row.UserId);
                return (
                    <Tooltip arrow title={`${user?.FirstName} ${user?.LastName}`}>
                        <Avatar
                            alt="User picture"
                            src={user?.Picture || BlankAvatar}
                        />
                    </Tooltip>
                );
            },
        },
        {
            field: "Actions",
            headerName: "Ações",
            width: 150,
            maxWidth: 300,
            align: "center",
            headerAlign: "center",
            colSpan: 4,
            renderCell: (params) => (
                <span>
                    <Tooltip
                        title={!isUserAllowedToView ? "Você não tem permissão pra fazer isso" : "Ver detalhes"}
                        arrow
                    >
                        <span>
                            <IconButton
                                disabled={!isUserAllowedToView}
                                onClick={(e) => handleViewButtonClick(params.row.Id)}
                            >
                                <Visibility />
                            </IconButton>
                        </span>
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <ThemeProvider theme={isCoffeeShop ? coffeeShopTheme : defaultTheme}>
            <CssBaseline />
            <Helmet title="Admin ONE | Vendas" />
            <AODataTable
                useDefaultPreComponent
                columns={columns}
                rows={sales!}
                isLoading={loadingSales || loadingUsers}
                title="Lista de Vendas"
            />
        </ThemeProvider>
    );
}
