import React, { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Avatar, Button, CssBaseline, Grid, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import { AddBox, Edit, ShoppingCartCheckout } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { coffeeShopTheme } from "../../../themes";
import AODataTable from "../../../components/Table/DataTable";
import { WrappedSale } from "../../../types/sales.type";
import CashierWrapper from "../../../wrappers/cashier.wrapper";
import BlankAvatar from "../../../assets/png/blank-avatar.png";
import GenericDialog from "../../../components/Dialogs/Generic";
import SaleForm from "../../../components/Forms/SaleForm";
import { PAYMENT_METHODS } from "../../../constants/sale.constant";
import { dateDiffInHours } from "../../../utils/date";
import * as SalesService from "../../../services/sales.service";
import { PromiseToast } from "../../../utils/toaster";


export default function CoffeeShopCashier() {
    const [showSaleFormDialog, setShowSaleFormDialog] = useState(false);
    const [saleToEdit, setSaleToEdit] = useState<WrappedSale>();
    const [showCloseCashierDialog, setShowCloseCashierDialog] = useState(false);
    const [isClosingCashier, setIsClosingCashier] = useState(false);
    const [cookies] = useCookies(['session_token']);
    const cashier = CashierWrapper.get();
    const estimatedRevenue = Number(cashier.Sales.reduce((acc, curr) => acc + curr.TotalValue!, 0)).toFixed(2);
    const amountOfProductsSold = cashier.Sales.map((s) => s.ProductSale).flat().reduce((acc, curr) => acc + curr?.Amount!, 0);
    const navigate = useNavigate();

    const getPaymentMethod = (id: number) => Object.values(PAYMENT_METHODS).find((pm) => pm.id === id);

    const columns: GridColDef<WrappedSale>[] = [
        { field: "ItemAmount", headerName: "Qt. de Ítens", width: 100, align: "center", headerAlign: "center", },
        {
            field: "PaymentMethodId",
            headerName: "Método de Pagamento",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const pm = getPaymentMethod(params.row.PaymentMethodId!);
                if (pm) {
                    return (
                        <span style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                            <pm.icon />
                            {pm?.friendlyName}
                        </span>
                    );
                }
                else return null;
            },
        },
        {
            field: "TotalValue",
            headerName: "Valor Total",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `R$${params.row.TotalValue?.toFixed(2)}`,
        },
        {
            field: "Actions",
            headerName: "Ações",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <span>
                    <IconButton onClick={(e) => handleEditButtonClick(params.row)}>
                        <Edit />
                    </IconButton>
                </span>
            ),
        },
    ];

    const toggleSaleFormDialog = (editing?: boolean) => {
        if (!editing) setSaleToEdit(undefined);
        setShowSaleFormDialog(!showSaleFormDialog);
    };
    const handleEditButtonClick = (sale: WrappedSale) => {
        setSaleToEdit(sale);
        toggleSaleFormDialog(true);
    };
    const toggleCloseCashierDialog = () => setShowCloseCashierDialog(!showCloseCashierDialog);
    const closeCashier = async () => {
        try {
            setIsClosingCashier(true);
            toggleCloseCashierDialog();
            await PromiseToast(
                SalesService.CreateSales(cashier.Sales, cookies.session_token),
                {
                    error: 'Não conseguimos fechar o caixa! Tente novamente ou entre em contato com o suporte técnico (Jojo 😅).',
                    pending: 'Fechando caixa...',
                    success: {
                        render() {
                            CashierWrapper.clear();
                            navigate("/coffee-shop");
                            return 'Caixa fechado!';
                        }
                    },
                }
            );
        } finally {
            setIsClosingCashier(false);
        }
    };

    return (
        <ThemeProvider theme={coffeeShopTheme}>
            <CssBaseline />
            <Helmet title="Coffee Shop | Caixa" />
            <GenericDialog
                onClose={toggleCloseCashierDialog}
                open={showCloseCashierDialog}
                title="Fechar Caixa"
                useConfirmAndCancelButtons
                onConfirm={closeCashier}
            >
                <span style={{ lineHeight: "3rem" }}>
                    <p style={{ fontSize: "1.2rem" }}>Resumo:</p>
                    <span style={{ lineHeight: "1.8rem" }}>
                        <p>Quantidade total de ítens vendidos: <strong>{amountOfProductsSold}</strong> 📊</p>
                        <p>Receita total: <strong>R${estimatedRevenue} 💸 💪</strong></p>
                        <p>Tempo que o caixa ficou aberto: <strong>{dateDiffInHours(new Date(cashier.OpenedAt!))} horas</strong></p>
                    </span>
                    <br/>
                    <p style={{ fontSize: "1.2rem" }}>Fechar o caixa faz com as vendas sejam computadas.</p>
                    <p style={{ fontSize: "1.2rem" }}>Não será possível registrar novas vendas nesse caixa.</p>
                    <p style={{ fontSize: "1.2rem" }}>Tem certeza que deseja fechar esse caixa agora?</p>
                </span>
            </GenericDialog>
            <GenericDialog
                title={`${!!saleToEdit ? 'Editar' : 'Registrar'} Venda`}
                onClose={toggleSaleFormDialog}
                open={showSaleFormDialog}
            >
                <SaleForm
                    isCoffeeShop={true}
                    onClose={toggleSaleFormDialog}
                    isEditing={!!saleToEdit}
                    sale={saleToEdit}
                />
            </GenericDialog>
            <AODataTable
                columns={columns}
                rows={cashier.Sales}
                title="Caixa"
                rowIdProp="uuid"
                preComponent={
                    <>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                display: "flex",
                                gap: "2rem",
                                justifyContent: "center"
                            }}
                        >
                            <Button
                                variant="contained"
                                startIcon={<AddBox />}
                                sx={{ minWidth: "12rem", maxWidth: "16rem" }}
                                onClick={(e) => toggleSaleFormDialog()}
                                disabled={isClosingCashier}
                            >
                                Registrar Venda
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<ShoppingCartCheckout />}
                                sx={{ minWidth: "12rem", maxWidth: "16rem" }}
                                onClick={toggleCloseCashierDialog}
                                color="secondary"
                                disabled={isClosingCashier}
                            >
                                Fechar Caixa
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <span style={{ display: "flex", gap: "1rem", alignItems: "center", color: "#fff" }}>
                                <span>
                                    Receita estimada: <strong>R${estimatedRevenue}</strong>
                                </span>
                                <span>-</span>
                                <span>Responsável:</span>
                                <span>
                                    <Tooltip
                                        arrow
                                        title={`${cashier.PersonInCharge?.FirstName} ${cashier.PersonInCharge?.LastName}`}
                                    >
                                        <Avatar src={cashier.PersonInCharge?.Picture || BlankAvatar} />
                                    </Tooltip>
                                </span>
                                <span>-</span>
                                <span>Aberto por:</span>
                                <span>
                                    <Tooltip
                                        arrow
                                        title={`${cashier.OpenedBy?.FirstName} ${cashier.OpenedBy?.LastName}`}
                                    >
                                        <Avatar src={cashier.OpenedBy?.Picture || BlankAvatar} />
                                    </Tooltip>
                                </span>
                            </span>
                        </Grid>
                    </>
                }
                preComponentAction={
                    <span style={{ display: "flex", gap: "1rem", alignItems: "center", color: "#fff" }}>
                        <span>
                            <Button
                                variant="contained"
                                startIcon={<AddBox />}
                            >
                                Registrar Venda
                            </Button>
                        </span>
                        <span>
                            Receita estimada: <strong>R${estimatedRevenue}</strong>
                        </span>
                        <span>-</span>
                        <span>Responsável:</span>
                        <span>
                            <Tooltip
                                arrow
                                title={`${cashier.PersonInCharge?.FirstName} ${cashier.PersonInCharge?.LastName}`}
                            >
                                <Avatar src={cashier.PersonInCharge?.Picture || BlankAvatar} />
                            </Tooltip>
                        </span>
                        <span>-</span>
                        <span>Aberto por:</span>
                        <span>
                            <Tooltip
                                arrow
                                title={`${cashier.OpenedBy?.FirstName} ${cashier.OpenedBy?.LastName}`}
                            >
                                <Avatar src={cashier.OpenedBy?.Picture || BlankAvatar} />
                            </Tooltip>
                        </span>
                    </span>
                }
            />
        </ThemeProvider>
    );
}
