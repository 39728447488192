import React, { useState } from "react";
import { Avatar, Button, Checkbox, CircularProgress, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { Product } from "../../../types/product.type";
import { useCoffeeShopStaff, useProducts } from "../../../hooks";
import BlankAvatar from "../../../assets/png/blank-avatar.png";
import { WrappedCashier } from "../../../types/sales.type";
import UserWrapper from "../../../wrappers/user.wrapper";
import "./CashierForm.styles.scss";
import CashierWrapper from "../../../wrappers/cashier.wrapper";
import { SuccessToast } from "../../../utils/toaster";


type CashierFormProps = {
    isEditing?: boolean;
    onClose?: (arg?: any) => void;
};

export default function CashierForm({ isEditing, onClose }: CashierFormProps) {
    const [personInCharge, setPersonInCharge] = useState<number>();
    const [cashierProducts, setCashierProducts] = useState<Product[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const hasAnyError = !personInCharge || !cashierProducts.length;
    const { staff, loadingStaff } = useCoffeeShopStaff();
    const { loadingProducts, products } = useProducts();
    const user = UserWrapper.get();

    const renderSelectedUser = (selectedId: number) => {
        const selected = staff?.find((s) => s.Id === selectedId);
        return (
            <span style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                <Avatar
                    alt="User picture"
                    src={selected?.Picture || BlankAvatar}
                    sx={{ height: "2rem", width: "2rem" }}
                />
                <span>{`${selected?.FirstName} ${selected?.LastName}`}</span>
            </span>
        );
    };
    const handlePersonInChargeSelection = (e: SelectChangeEvent<number>) => setPersonInCharge(Number(e.target.value));
    const handleProductSelection = (selected: Product) => {
        if (cashierProducts.find((cp) => cp.Id === selected.Id)) {
            setCashierProducts((prev) => {
                const updated = [...prev];
                return updated.filter((up) => up.Id !== selected.Id);
            });
        }
        else {
            setCashierProducts((prev) => {
                const updated = [...prev].filter((up) => up.Id !== selected.Id);
                return [...updated, selected];
            });
        }
    };
    const renderSelectedProducts = (selected: Product[]) => (
        <span style={{ display: "flex", gap: "0.5rem" }}>
            {selected.map((s, i) => (
                <span key={i}>{s.Name}{i - selected.length !== -1 && ','}</span>
            ))}
        </span>
    );
    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!hasAnyError) {
            const data: WrappedCashier = {
                Products: cashierProducts,
                Sales: [],
                OpenedAt: new Date(),
                OpenedBy: user.data,
                PersonInCharge: staff?.find((s) => s.Id === personInCharge),
            };

            CashierWrapper.set(data);
            SuccessToast('Caixa aberto!');
            setIsSubmitting(false);
            onClose?.();
        }
    };

    return (
        <div className="create-cashier-form">
            <div className="basic-info">
                <FormControl>
                    <InputLabel id="person-in-charge" required>Responsável</InputLabel>
                    <Select
                        labelId="person-in-charge"
                        id="person-in-charge"
                        label="Responsável"
                        value={personInCharge!}
                        disabled={loadingStaff}
                        renderValue={renderSelectedUser}
                        onChange={handlePersonInChargeSelection}
                    >
                        {staff?.map((s, i) => (
                            <MenuItem value={s.Id} key={i}>
                                <span style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                                    <Avatar
                                        alt="User picture"
                                        src={s.Picture || BlankAvatar}
                                        sx={{ height: "2rem", width: "2rem" }}
                                    />
                                    <span>{`${s.FirstName} ${s.LastName}`}</span>
                                </span>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="cashierProducts" required>Produtos</InputLabel>
                    <Select
                        labelId="cashierProducts"
                        id="cashierProducts"
                        label="Produtos"
                        value={cashierProducts}
                        disabled={loadingProducts}
                        renderValue={renderSelectedProducts}
                        multiple
                    >
                        {products?.map((product, i) => (
                            <MenuItem
                                onClick={(e) => handleProductSelection(product)}
                                key={i}
                            >
                                <Checkbox
                                    checked={!!cashierProducts.find((cp) => cp.Id === product.Id)}
                                />
                                <ListItemText primary={`${product.Name} - R$${Number(product.SalePrice).toFixed(2)}`} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={hasAnyError || isSubmitting}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" size={24} />
                    :
                    isEditing ? 'Salvar' : 'Abrir'
                }
            </Button>
        </div>
    );
}
