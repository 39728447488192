import GoogleLoginButton from "../../components/Buttons/GoogleLoginButton";


export default function AnonymousHome() {
    return (
        <>
            <h2>Bem vindo(a) Admin ONE!</h2>
            <h4>Faça login para continuar</h4>
            <GoogleLoginButton />
        </>
    );
}
