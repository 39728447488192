import axios from "axios";
import { connections } from "../config";
import { PaymentMethod, Sale, WrappedSale } from "../types/sales.type";
import { ApiHeaders } from "../types/auth.types";
import { ALL_COFFEE_SHOP_ACCESSES, CREATE_SALE_ALLOWED_ACCESSES, LIST_SALES_ALLOWED_ACCESSES } from "../constants/access.constant";
import { GenerateInternalToken } from "@one-tech/access-control-lib";
import { APP_ID } from "../constants/generic.constant";


const SalesApiClient = axios.create({ baseURL: connections.salesApiUrl });

export async function ListSales(sessionToken: string): Promise<Sale[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify([...LIST_SALES_ALLOWED_ACCESSES, ...ALL_COFFEE_SHOP_ACCESSES]),
        };
        const res = await SalesApiClient.get("/sales/list", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'SalesService.ListSales':", err);
        throw err;
    }
}

export async function ListPaymentMethods(): Promise<PaymentMethod[]> {
    try {
        const headers = {
            Authorization: await GenerateInternalToken(APP_ID),
        };
        const res = await SalesApiClient.get("/payment-methods", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'SalesService.ListPaymentMethods':", err);
        throw err;
    }
}

export async function CreateSales(data: WrappedSale[], sessionToken: string): Promise<Sale[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(CREATE_SALE_ALLOWED_ACCESSES),
        };
        const res = await SalesApiClient.post("/sales", data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'SalesService.CreateSales':", err);
        throw err;
    }
}

export async function GetSale(id: number, sessionToken: string): Promise<Sale> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(CREATE_SALE_ALLOWED_ACCESSES),
        };
        const res = await SalesApiClient.get(`/sale/${id}`, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'SalesService.GetSale':", err);
        throw err;
    }
}
