import React, { useEffect, useState } from "react";
import { Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AddBox, Business, IndeterminateCheckBox } from "@mui/icons-material";

import { Product } from "../../../types/product.type";
import { useDepartments, useProducts } from "../../../hooks";
import CashierWrapper from "../../../wrappers/cashier.wrapper";
import { PAYMENT_METHODS } from "../../../constants/sale.constant";
import { ProductSale, WrappedSale } from "../../../types/sales.type";
import { SuccessToast } from "../../../utils/toaster";
import { makeUUID } from "../../../utils/purchase";
import UserWrapper from "../../../wrappers/user.wrapper";
import { coffeeShopDepartmentId } from "../../../config";
import "./SaleForm.styles.scss";


type SaleFormProps = {
    isCoffeeShop?: boolean;
    isEditing?: boolean;
    onClose?: (arg?: any) => void;
    sale?: WrappedSale;
};

export default function SaleForm({ isCoffeeShop, isEditing, onClose, sale }: SaleFormProps) {
    const [saleProducts, setSaleProducts] = useState<Partial<ProductSale>[]>([]);
    const [paymentMethodId, setPaymentMethodId] = useState<number>();
    const [departmentId, setDepartmentId] = useState<number>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { loadingProducts, products } = useProducts();
    const { loadingDepartments, departments } = useDepartments();
    const paymentMethods = Object.values(PAYMENT_METHODS);
    const cashier = CashierWrapper.get();
    const productsForSale = isCoffeeShop && cashier && !!cashier.OpenedAt ? cashier.Products : products!;
    const saleRevenue = saleProducts.reduce((acc, curr) => acc + Number(curr.TotalValue), 0).toFixed(2);
    const hasAnyError = !saleProducts.length && !paymentMethodId;
    const user = UserWrapper.get();
    const coffeeShopDepartment = departments?.find((d) => d.Id === coffeeShopDepartmentId);

    const getAmountOfProduct = (id: number) => saleProducts.find((sp) => sp.ProductId === id)?.Amount;
    const renderSelectedPaymentMethod = (id: number) => {
        const selected = paymentMethods?.find((pm) => pm.id === id)!;
        return (
            <span style={{ display: "flex", gap: "1.5rem" }}>
                <selected.icon />
                {selected?.friendlyName}
            </span>
        );
    };
    const renderSelectedDepartment = (id?: number) => {
        if (id && departmentId) return (
            <span style={{ display: "flex", gap: "1.5rem" }}>
                <Business />
                {departments?.find((d) => d.Id === Number(id))?.Name}
            </span>
        );
        else return null;
    };
    const handleProductAddClick = (selected: Product) => {
        let product = saleProducts.find((sp) => sp.ProductId === selected.Id);
        if (product) {
            product.Amount! += 1;
            product.TotalValue! = selected.SalePrice * product.Amount!;
        }
        else {
            product = {
                Amount: 1,
                ProductId: selected.Id,
                TotalValue: selected.SalePrice,
                UnitPrice: selected.SalePrice,
            }
        }
        setSaleProducts((prev) => {
            const updated = [...prev].filter((up) => up.ProductId !== selected.Id);
            return [...updated, product!];
        });
    };
    const handleProductMinusClick = (selected: Product) => {
        const product = saleProducts.find((sp) => sp.ProductId === selected.Id);
        if (product && product.Amount) product.Amount -= 1;
        if (product && product?.Amount !== 0) {
            setSaleProducts((prev) => {
                const updated = [...prev].filter((up) => up.ProductId !== selected.Id);
                return [...updated, product!];
            });
        }
        else {
            setSaleProducts((prev) => {
                const updated = [...prev];
                return updated.filter((up) => up.ProductId !== selected.Id);
            });
        }
    };
    const handlePaymentMethodSelection = (e: SelectChangeEvent<number>) => setPaymentMethodId(Number(e.target.value));
    const handleDepartmentSelection = (e: SelectChangeEvent<number>) => setDepartmentId(Number(e.target.value));
    const handleSubmit = () => {
        setIsSubmitting(true);
        if (!hasAnyError) {
            const depId = isCoffeeShop && coffeeShopDepartment ? coffeeShopDepartment.Id : departmentId;
            const data: WrappedSale = {
                ItemAmount: saleProducts.reduce((acc, curr) => acc + curr.Amount!, 0),
                PaymentMethodId: paymentMethodId,
                ProductSale: saleProducts.map((sp) => ({
                    Amount: sp.Amount,
                    ProductId: sp.ProductId,
                    UnitPrice: sp.UnitPrice,
                    TotalValue: sp.TotalValue,
                })),
                TotalValue: Number(saleRevenue),
                UserId: user.data?.Id,
                DepartmentId: depId,
                uuid: isEditing && sale ? sale.uuid : makeUUID(),
            };

            if (isEditing && sale) {
                CashierWrapper.updateSale(data);
                SuccessToast('Venda atualizada!');
                setIsSubmitting(false);
                onClose?.();
            }
            else {
                CashierWrapper.addSale(data);
                SuccessToast('Venda registrada!');
                setIsSubmitting(false);
                onClose?.();
            }
        }
    };

    useEffect(() => {
        if (isEditing && sale) {
            setSaleProducts(sale.ProductSale!);
            setPaymentMethodId(sale.PaymentMethodId);
            setDepartmentId(sale.DepartmentId);
        }
    }, [isEditing, sale]);

    return (
        <div className="sale-form">
            <div className="basic-info">
                <FormControl>
                    {!loadingProducts ?
                        productsForSale.map((p, i) => (
                            <span
                                key={i}
                                style={{
                                    display: "flex",
                                    gap: "6rem",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "1.2rem",
                                }}
                            >
                                <span>{p.Name}</span>
                                <span style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                                    <IconButton color="error" onClick={(e) => handleProductMinusClick(p)}>
                                        <IndeterminateCheckBox />
                                    </IconButton>
                                    <span>{getAmountOfProduct(p.Id) || 0}</span>
                                    <IconButton color="success" onClick={(e) => handleProductAddClick(p)}>
                                        <AddBox />
                                    </IconButton>
                                </span>
                            </span>
                        ))
                        :
                        null
                    }
                </FormControl>
                <FormControl>
                    <InputLabel id="payment-method" required>Método de pagamento</InputLabel>
                    <Select
                        labelId="payment-method"
                        id="payment-method"
                        label="Método de pagamento"
                        value={isEditing && sale ? sale?.PaymentMethodId : paymentMethodId}
                        renderValue={renderSelectedPaymentMethod}
                        onChange={handlePaymentMethodSelection}
                    >
                        {paymentMethods?.map((pm, i) => (
                            <MenuItem
                                key={i}
                                value={pm.id}
                                sx={{ display: "flex", gap: "1.5rem" }}
                            >
                                <pm.icon />
                                {pm.friendlyName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {!isCoffeeShop &&
                    <FormControl>
                        <InputLabel id="department" required disabled={loadingDepartments}>Departamento</InputLabel>
                        <Select
                            labelId="department"
                            id="Department"
                            label="Departamento"
                            value={isEditing && sale ? sale?.DepartmentId : departmentId}
                            renderValue={renderSelectedDepartment}
                            onChange={handleDepartmentSelection}
                            disabled={loadingDepartments}
                        >
                            {departments?.map((d, i) => (
                                <MenuItem
                                    key={i}
                                    value={d.Id}
                                    sx={{ display: "flex", gap: "1.5rem" }}
                                >
                                    <Business />
                                    {d.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
            </div>
            <div className="basic-info">
                {!!saleProducts.length && <span>Total: R${saleRevenue}</span>}
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={hasAnyError || isSubmitting}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" size={24} />
                    :
                    isEditing ? 'Salvar' : 'Finalizar'
                }
            </Button>
        </div>
    );
}
