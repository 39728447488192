import axios from "axios";

import { connections } from "../config";
import { ApiHeaders } from "../types/auth.types";
import {
    CREATE_STOCK_ALLOWED_ACCESSES,
    DELETE_STOCK_ALLOWED_ACCESSES,
    EDIT_STOCK_ALLOWED_ACCESSES,
    LIST_STOCKS_ALLOWED_ACCESSES,
    VIEW_STOCK_ALLOWED_ACCESSES
} from "../constants/access.constant";
import { CreateStockData, Stock } from "../types/stock.types";


const StockApiClient = axios.create({ baseURL: connections.stockApiUrl });

export async function ListStocks(sessionToken: string): Promise<Stock[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(LIST_STOCKS_ALLOWED_ACCESSES),
        };

        const res = await StockApiClient.get("/stocks/list", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'StockService.ListStocks':", err);
        throw err;
    }
}

export async function CreateStock(sessionToken: string, data: CreateStockData): Promise<Stock> {
    try {
        const headers: ApiHeaders = {
            "Admin-ONE-Allowed-Accesses": JSON.stringify(CREATE_STOCK_ALLOWED_ACCESSES),
            Authorization: sessionToken,
        };

        const res = await StockApiClient.post("/", data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'StockService.CreateStock'", err);
        throw err;
    }
}

export async function UpdateStock(stockId: number, sessionToken: string, data: Partial<CreateStockData>) {
    try {
        const headers: ApiHeaders = {
            "Admin-ONE-Allowed-Accesses": JSON.stringify(EDIT_STOCK_ALLOWED_ACCESSES(stockId)),
            Authorization: sessionToken,
        };

        const res = await StockApiClient.patch(`/${stockId}`, data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'StockService.UpdateStock'", err);
        throw err;
    }
}

export async function DeleteStock(id: number, sessionToken: string) {
    try {
        const headers: ApiHeaders = {
            "Admin-ONE-Allowed-Accesses": JSON.stringify(DELETE_STOCK_ALLOWED_ACCESSES(id)),
            Authorization: sessionToken,
        };

        const res = await StockApiClient.delete(`/${id}`, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'StockService.DeleteStock'", err);
        throw err;
    }
}

export async function GetStock(id: number, sessionToken: string): Promise<Stock> {
    try {
        const headers: ApiHeaders = {
            "Admin-ONE-Allowed-Accesses": JSON.stringify(VIEW_STOCK_ALLOWED_ACCESSES(id)),
            Authorization: sessionToken,
        };
        const res = await StockApiClient.get(`/${id}`, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'StockService.GetStock'", err);
        throw err;
    }
}
