import axios from "axios";

import { connections } from "../config";
import { ApiHeaders } from "../types/auth.types";
import {
    ALL_COFFEE_SHOP_ACCESSES,
    ALL_SYSTEM_MANAGING_ACCESSES,
    DELETE_DEPARTMENT_ALLOWED_ACCESSES,
    EDIT_DEPARTMENT_ALLOWED_ACCESSES,
    LIST_DEPARTMENTS_ALLOWED_ACCESSES,
    VIEW_DEPARTMENT_ALLOWED_ACCESSES
} from "../constants/access.constant";
import { CreateDepartmentData, Department } from "../types/department.types";


const DepartmentApiClient = axios.create({ baseURL: connections.departmentApiUrl });

export async function ListDepartments(sessionToken: string): Promise<Department[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify([...LIST_DEPARTMENTS_ALLOWED_ACCESSES, ...ALL_COFFEE_SHOP_ACCESSES]),
        };

        const res = await DepartmentApiClient.get("/departments/list", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'DepartmentService.ListDepartments':", err);
        throw err;
    }
}

export async function CreateDepartment(sessionToken: string, data: CreateDepartmentData): Promise<Department> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(ALL_SYSTEM_MANAGING_ACCESSES),
        };
        const { Name, BranchId } = data;
        const res = await DepartmentApiClient.post("/", { Name, BranchId }, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'DepartmentService.CreateDepartment':", err);
        throw err;
    }
}

export async function UpdateDepartment(id: number, sessionToken: string, data: Partial<CreateDepartmentData>) {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(EDIT_DEPARTMENT_ALLOWED_ACCESSES(id)),
        };
        const { Name, BranchId } = data;
        const res = await DepartmentApiClient.patch(`/${id}`, { Name, BranchId }, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'DepartmentService.UpdateDepartment':", err);
        throw err;
    }
}

export async function DeleteDepartment(id: number, sessionToken: string) {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(DELETE_DEPARTMENT_ALLOWED_ACCESSES(id)),
        };
        const res = await DepartmentApiClient.delete(`/${id}`, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'DepartmentService.DeleteDepartment'", err);
        throw err;
    }
}

export async function GetDepartmentById(id: number, sessionToken: string): Promise<Department> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(VIEW_DEPARTMENT_ALLOWED_ACCESSES(id)),
        };

        const res = await DepartmentApiClient.get(`/${id}`, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'DepartmentService.GetDepartmentById':", err);
        throw err;
    }
}
