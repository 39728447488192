import * as React from "react";
import { Button } from "@mui/material";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { CreateSessionToken } from "@one-tech/access-control-lib";
import { useCookies } from "react-cookie";

import { ReactComponent as GoogleIcon } from "../../../assets/svg/google.svg";
import { ErrorToast, SuccessToast } from "../../../utils/toaster";
import * as UserService from "../../../services/user.service";
import UserWrapper from "../../../wrappers/user.wrapper";
import * as AccessUtils from "../../../utils/access";


export default function GoogleLoginButton() {
    const [, setCookie] = useCookies();
    const handleLoginSuccess = async (res: Omit<TokenResponse, "error" | "error_description" | "error_uri">) => {
        try {
            const data = await UserService.CheckUserAuth(res.access_token);
            if (!data) {
                ErrorToast("Parece que você não pode acessar a plataforma 😕! Fale com um administrador!");
                return;
            }
            const { user, googleUser } = data;
            const access = AccessUtils.MakeUserAccessObject(user);
            const isCoffeeShopStaff = AccessUtils.isCoffeeShopStaff(access);
            const session = await CreateSessionToken(user.Id, access);
            setCookie("session_token", session.token);
            UserWrapper.set({ session: { auth: session.auth }, access, data: user!, google: googleUser, isCoffeeShopStaff, });
            SuccessToast(`Você está logado! 🙏`);
        } catch (err) {
            console.error("Error in 'handleLoginSuccess':",err);
            ErrorToast("Ocorreu um erro durante o seu login 😫! Tente novamente ou fale com um administrador!");
        }
    };

    const handleLoginFail = (res: Pick<TokenResponse, "error" | "error_description" | "error_uri">) => {
        ErrorToast('O login falhou! Fale com um administrador para garantir que você tem acesso a plataforma!');
    };

    const signIn = useGoogleLogin({
        onError: handleLoginFail,
        onSuccess: handleLoginSuccess,
    });

    return (
        <Button
            variant="contained"
            endIcon={<GoogleIcon height="1rem" width="1rem" />} onClick={() => signIn()}
            sx={{
                marginTop: "2rem",
                textTransform: "none",
            }}
        >
            Entrar com o Google
        </Button>
    );
}
