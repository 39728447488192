import { UserAccess } from "@one-tech/access-control-lib/dist/src/types";

import { AllowedAccess } from "../types/auth.types";
import { ACCESS_LEVELS } from "../constants/access.constant";
import { User } from "../types/user.types";


export function CheckIfUserHasPermission(userAccess: UserAccess, allowedAccesses?: AllowedAccess[]) {
    let allowed = false;
    if (allowedAccesses) {
        for (const { levelId, resource, resourceId } of allowedAccesses) {
            if (resourceId) {
                const resourceProfile = userAccess?.[resource]?.find((r) => r.id === resourceId);
                if (resourceProfile && resourceProfile.level === levelId) allowed = true;
            }
            else {
                const resourceProfiles = userAccess?.[resource]?.filter((r) => r.level === levelId);
                if (resourceProfiles && resourceProfiles.length) allowed = true;
            }
        }
    }
    else {
        allowed = true;
    }
    return allowed;
}

export function MakeUserAccessObject(user: User): UserAccess {
    return {
        departments: user?.DepartmentUserProfile.map((profile) => ({ id: profile.DepartmentId, level: profile.AccessLevelId })),
        stocks: user?.StockUserProfile.map((profile) => ({ id: profile.StockId, level: profile.AccessLevelId })),
        systems: user?.SystemUserProfile.map((profile) => ({ id: profile.Id, level: profile.AccessLevelId })),
    }
}

export function isCoffeeShopStaff(userAccess: UserAccess) {
    let isStaff = false;
    if (userAccess.systems) {
        for (const { level } of userAccess.systems) {
            if (level === ACCESS_LEVELS.coffee_shop_manager.id || level === ACCESS_LEVELS.coffee_shop_worker.id) isStaff = true;
        }
    }
    return isStaff;
}
