import axios from "axios";

import { connections } from "../config";
import { ApiHeaders } from "../types/auth.types";
import { CREATE_PURCHASE_ALLOWED_ACCESSES, LIST_PURCHASES_ALLOWED_ACCESSES } from "../constants/access.constant";
import { CreatePurchaseData, Purchase, PurchaseType } from "../types/purchase.types";
import { GenerateInternalToken } from "@one-tech/access-control-lib";
import { APP_ID } from "../constants/generic.constant";


const PurchaseApiClient = axios.create({ baseURL: connections.purchaseApiUrl });

export async function ListPurchases(sessionToken: string): Promise<Purchase[]> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(LIST_PURCHASES_ALLOWED_ACCESSES),
        };
        const res = await PurchaseApiClient.get("/purchases/list", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'PurchaseService.ListPurchases':", err);
        throw err;
    }
}

export async function ListPurchaseTypes(): Promise<PurchaseType[]> {
    try {
        const headers = {
            Authorization: await GenerateInternalToken(APP_ID),
        };
        const res = await PurchaseApiClient.get("/purchases/types", { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'PurchaseService.ListPurchaseTypes':", err);
        throw err;
    }
}

export async function CreatePurchase(data: CreatePurchaseData, sessionToken: string): Promise<Purchase> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(CREATE_PURCHASE_ALLOWED_ACCESSES),
        };
        const res = await PurchaseApiClient.post("/purchase", data, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'PurchaseService.CreatePurchase':", err);
        throw err;
    }
}

export async function GetPurchase(purchaseId: number, sessionToken: string): Promise<Purchase> {
    try {
        const headers: ApiHeaders = {
            Authorization: sessionToken,
            "Admin-ONE-Allowed-Accesses": JSON.stringify(LIST_PURCHASES_ALLOWED_ACCESSES),
        };
        const res = await PurchaseApiClient.get(`/purchase/${purchaseId}`, { headers });
        return res.data;
    } catch (err) {
        console.error("Error in 'PurchaseService.GetPurchase':", err);
        throw err;
    }
}
