import React, { useEffect, useState } from "react";
import { Button, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Business as BusinessIcon } from "@mui/icons-material";
import { useCookies } from "react-cookie";

import { CreateStockData, Stock } from "../../../types/stock.types";
import { Department } from "../../../types/department.types";
import { ErrorToast, InfoToast } from "../../../utils/toaster";
import * as StockService from "../../../services/stock.service";
import "./StockForm.styles.scss";


type StockFormProps = {
    isEditing?: boolean;
    onClose?: (arg?: any) => void;
    stock?: Stock;
    departments?: Department[];
};

export default function StockForm({ isEditing, departments, onClose, stock }: StockFormProps) {
    const [name, setName] = useState({ error: false, helperTxt: "", value: "" });
    const [selectedDepartmentId, setSelectedDepartmentId] = useState<number>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cookies] = useCookies(['session_token']);
    const hasAnyError = name.error || !selectedDepartmentId;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName({ error: false, helperTxt: "", value: e.target.value });
    const handleDepartmentSelection = (id: number) => setSelectedDepartmentId(id);
    const renderSelectedDepartment = (id?: string) => {
        if (id && selectedDepartmentId) return (
            <span style={{ display: "flex", gap: "1.5rem" }}>
                <BusinessIcon />
                {departments?.find((d) => d.Id === Number(id))?.Name}
            </span>
        );
        else return null;
    };

    const makePatchData = () => {
        const patchData: Partial<CreateStockData> = {};
        if (name.value !== stock?.Name) patchData.Name = name.value;
        if (selectedDepartmentId !== stock?.DepartmentId) patchData.DepartmentId = selectedDepartmentId;
        return patchData;
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        let isValid = true;
        if (!name.value) {
            isValid = false;
            setName((prev) => ({ ...prev, error: true, helperTxt: "Esse campo é obrigatório!" }));
        }
        if (!selectedDepartmentId) isValid = false;

        if (isValid) {
            const data: CreateStockData = {
                DepartmentId: selectedDepartmentId!,
                Name: name.value,
            };
            if (isEditing && stock) {
                const patchData = makePatchData();
                if (!Object.entries(patchData).length) {
                    InfoToast("Nada mudou! Antes de salvar, por favor altere alguma informação do estoque!");
                    setIsSubmitting(false);
                }
                else {
                    StockService.UpdateStock(stock.Id, cookies.session_token, patchData)
                    .then(() => {
                        onClose?.(true);
                    })
                    .catch((err) => {
                        if (err?.response?.status === 404) {
                            InfoToast('O estoque que você está tentando editar não existe! Por favor atualize a página!');
                        }
                        else {
                            ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                        }
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
                }
            }
            else {
                StockService.CreateStock(cookies.session_token, data)
                .then(() => {
                    onClose?.(true);
                })
                .catch((err) => {
                    if (err?.response?.status === 409) {
                        ErrorToast('Já existe um estoque com esse nome!');
                    }
                    else {
                        ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
            }
        }
    }

    useEffect(() => {
        if (isEditing && stock) {
            setName((prev) => ({ ...prev, value: stock.Name }));
            setSelectedDepartmentId(stock.DepartmentId);
        }
    }, [isEditing, stock]);

    return (
        <div className="create-stock-form">
            <div className="basic-info">
                <h5>Informações Básicas</h5>
                <TextField
                    variant="outlined"
                    name="name"
                    label="Nome"
                    value={name.value}
                    onChange={handleNameChange}
                />
                {name.error && name.helperTxt && <FormHelperText>{name.helperTxt}</FormHelperText>}
            </div>
            <div className="stock-department">
                <h5>Departamento</h5>
                <FormControl>
                    <InputLabel id="stock-department-label" disabled={!departments || !departments.length}>Departamento associado</InputLabel>
                    <Select
                        labelId="stock-department-label"
                        id="departments"
                        label="Departamento associado"
                        renderValue={renderSelectedDepartment}
                        disabled={!departments?.length}
                        onChange={({ target }) => handleDepartmentSelection(Number(target.value))}
                        error={isSubmitting && !selectedDepartmentId}
                        value={String(selectedDepartmentId)}
                        required
                    >
                        {departments?.map((department, i) => (
                            <MenuItem
                                key={i}
                                value={String(department.Id)}
                                sx={{ display: "flex", gap: "1.5rem" }}
                            >
                                <BusinessIcon />
                                {department.Name}
                            </MenuItem>
                        ))}
                    </Select>
                    {isSubmitting && !selectedDepartmentId && <FormHelperText>Um estoque precisa estar associado a um departamento!</FormHelperText>}
                </FormControl>
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={hasAnyError || isSubmitting}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" size={24} />
                    :
                    isEditing ? 'Salvar' : 'Criar'
                }
            </Button>
        </div>
    );
}
