import React, { useState } from "react";
import { Avatar, Button, IconButton, TextField, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet";
import { AddBox, DeleteForever as DeleteIcon, Edit as EditIcon, MoreHorizRounded as MoreIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { useCookies } from "react-cookie";
import { useQueryClient } from "@tanstack/react-query";

import UserWrapper from "../../wrappers/user.wrapper";
import { CheckIfUserHasPermission } from "../../utils/access";
import {
    ALL_SYSTEM_MANAGING_ACCESSES,
    DELETE_DEPARTMENT_ALLOWED_ACCESSES,
    EDIT_DEPARTMENT_ALLOWED_ACCESSES,
    VIEW_DEPARTMENT_ALLOWED_ACCESSES
} from "../../constants/access.constant";
import { useDepartments, useUsers } from "../../hooks";
import BlankAvatar from "../../assets/png/blank-avatar.png";
import { User } from "../../types/user.types";
import { Department } from "../../types/department.types";
import GenericDialog from "../../components/Dialogs/Generic";
import DepartmentForm from "../../components/Forms/DepartmentForm";
import { PromiseToast, SuccessToast } from "../../utils/toaster";
import * as DepartmentService from "../../services/department.service";
import AODataTable from "../../components/Table/DataTable";
import "./Departments.styles.scss";


export default function Departments() {
    const [showDepartmentFormDialog, setShowDepartmentFormDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [departmentToEdit, setDepartmentToEdit] = useState<Department>();
    const [departmentToDelete, setDepartmentToDelete] = useState<Department>();
    const [isDeleteInputTxtValid, setIsDeleteInputTxtValid] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [cookies] = useCookies(["session_token"]);
    const user = UserWrapper.get();
    const { departments, loadingDepartments } = useDepartments();
    const { users, loadingUsers } = useUsers();
    const queryClient = useQueryClient();

    const isUserAllowedToCreate = CheckIfUserHasPermission(
        user.access!,
        ALL_SYSTEM_MANAGING_ACCESSES,
    );
    const isUserAllowedToEdit = (departmentId: number) => CheckIfUserHasPermission(
        user.access!,
        EDIT_DEPARTMENT_ALLOWED_ACCESSES(departmentId),
    );
    const isUserAllowedToDelete = (departmentId: number) => CheckIfUserHasPermission(
        user.access!,
        DELETE_DEPARTMENT_ALLOWED_ACCESSES(departmentId),
    );
    const isUserAllowedToView = (departmentId: number) => CheckIfUserHasPermission(
        user.access!,
        VIEW_DEPARTMENT_ALLOWED_ACCESSES(departmentId),
    );

    const columns: GridColDef<Department>[] = [
        { field: "Name", headerName: "Nome", width: 120, align: "center", headerAlign: "center", },
        {
            field: "Branch",
            headerName: "Filial",
            width: 70,
            maxWidth: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.Branch.Name,
        },
        {
            field: "Members",
            headerName: "Membros",
            width: 200,
            maxWidth: 300,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const members = users?.filter((u) => u.DepartmentUserProfile.some((dup) => dup.DepartmentId === params.row.Id));
                if (members && members.length) {
                    return (
                        <span className="department-members">
                            {members.slice(0, 4).map((m: User, i) => (
                                <Tooltip
                                    key={i}
                                    title={`${m.FirstName} ${m.LastName}`}
                                    arrow
                                >
                                    <Avatar
                                        alt="User picture"
                                        src={m.Picture || BlankAvatar}
                                        sx={{ height: "2rem", width: "2rem" }}
                                    />
                                </Tooltip>
                            ))}
                            {members.length > 4 && (
                                <Tooltip title={`Mais ${members.length - 3} membros`} arrow>
                                    <MoreIcon sx={{ height: "2rem", width: "2rem" }} />
                                </Tooltip>
                            )}
                        </span>
                    );
                }
                else {
                    return '-';
                }
            },
        },
        {
            field: "Actions",
            headerName: "Ações",
            width: 150,
            maxWidth: 300,
            align: "center",
            headerAlign: "center",
            colSpan: 4,
            renderCell: (params) => (
                <span>
                    <Tooltip
                        title={!isUserAllowedToView(params.row.Id) ? "Você não tem permissão pra fazer isso" : "Ver detalhes (em desenvolvimento)"}
                        arrow
                    >
                        <span>
                            <IconButton disabled={true}>
                                <VisibilityIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={!isUserAllowedToEdit(params.row.Id) ? "Você não tem permissão pra fazer isso" : "Editar"}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToEdit(params.row.Id)} onClick={(e) => handleEditButtonClick(params.row)}>
                                <EditIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={!isUserAllowedToDelete(params.row.Id) ? "Você não tem permissão pra fazer isso" : "Deletar"}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToDelete(params.row.Id)} onClick={(e) => { toggleDeleteDialog(params.row); }}>
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </span>
            ),
        },
    ];

    const validateDeleteInputTxt = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsDeleteInputTxtValid(e.target.value === departmentToDelete?.Name);
    };
    const toggleDeleteDialog = (clickedDepartment?: Department) => {
        if (clickedDepartment) setDepartmentToDelete(clickedDepartment);
        setShowDeleteDialog(!showDeleteDialog);
    };
    const toggleDepartmentFormDialog = (submitted?: boolean) => {
        if (typeof submitted === "boolean" && submitted) {
            queryClient.invalidateQueries(['departments']).then(() => {
                queryClient.invalidateQueries(['users']).then(() => SuccessToast(`Departamento ${isEditing ? 'atualizado' : 'criado'}!`));
            });
        }
        setShowDepartmentFormDialog(!showDepartmentFormDialog);
    };
    const handleEditButtonClick = (d: Department) => {
        setIsEditing(true);
        setDepartmentToEdit(d);
        toggleDepartmentFormDialog();
    };
    const handleCreateButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setIsEditing(false);
        toggleDepartmentFormDialog();
    };
    const deleteDepartment = async () => {
        if (departmentToDelete) {
            toggleDeleteDialog();
            await PromiseToast(
                DepartmentService.DeleteDepartment(departmentToDelete.Id, cookies.session_token),
                {
                    error: "Não conseguimos deletar o departamento. Por favor tente novamente!",
                    pending: "Deletando departamento...",
                    success: "Departamento deletado!",
                }
            );
            await queryClient.invalidateQueries(['departments']);
        }
    };

    return (
        <>
            <Helmet title="Admin ONE | Departamentos" />
            <GenericDialog
                onClose={toggleDeleteDialog}
                open={showDeleteDialog}
                title="Deletar Departamento"
                useConfirmAndCancelButtons
                onConfirm={deleteDepartment}
                disableConfirm={!isDeleteInputTxtValid}
            >
                <span style={{ lineHeight: "3rem" }}>
                    <p style={{ fontSize: "1.2rem" }}>Tem certeza que deseja deletar esse departamento?</p>
                    <p style={{ fontSize: "1.2rem" }}>Para confirmar digite <strong>{departmentToDelete?.Name}</strong> no campo abaixo:</p>
                    <TextField fullWidth onChange={validateDeleteInputTxt} size="small" />
                </span>
            </GenericDialog>
            <GenericDialog
                onClose={toggleDepartmentFormDialog}
                open={showDepartmentFormDialog}
                title={`${isEditing ? 'Editar' : 'Criar'} Departamento`}
            >
                <DepartmentForm
                    department={departmentToEdit}
                    isEditing={isEditing}
                    onClose={toggleDepartmentFormDialog}
                    users={users!}
                />
            </GenericDialog>
            <AODataTable
                columns={columns}
                rows={departments!}
                isLoading={loadingDepartments || loadingUsers}
                useDefaultPreComponent
                title="Lista de Departamentos"
                preComponentAction={
                    <Tooltip
                        title={!isUserAllowedToCreate && "Você não tem permissão pra fazer isso"}
                        disableHoverListener={isUserAllowedToCreate}
                        arrow
                    >
                        <span>
                            <Button
                                startIcon={<AddBox />}
                                variant="contained"
                                sx={{ minWidth: "14rem", maxWidth: "16rem" }}
                                disabled={!isUserAllowedToCreate || loadingDepartments}
                                classes={{ disabled: "create-department-btn-disabled" }}
                                onClick={handleCreateButtonClick}
                            >
                                Criar Departamento
                            </Button>
                        </span>
                    </Tooltip>
                }
            />
        </>
    );
}
