import React, { useEffect, useState } from "react";
import { Autocomplete, Button, CircularProgress, FilterOptionsState, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, createFilterOptions } from "@mui/material";
import { useCookies } from "react-cookie";
import { NumberFormatBase } from "react-number-format";
import { Business } from "@mui/icons-material";

import { Product } from "../../../types/product.type";
import { useCategories, useDepartments } from "../../../hooks";
import { ErrorToast, InfoToast } from "../../../utils/toaster";
import * as ProductService from "../../../services/product.service";
import { PRODUCT_NAME_MAX_LENGTH } from "../../../constants/generic.constant";
import "./ProductForm.styles.scss";
import { coffeeShopDepartmentId } from "../../../config";



type ProductFormProps = {
    onClose?: (arg?: any) => void;
    product?: Product;
    isEditing?: boolean;
    isCoffeeShop?: boolean;
};

type SelectedCategory = {
    inputValue?: string;
    name: string;
};

const filter = createFilterOptions<SelectedCategory>();

export default function ProductForm({ product, onClose, isEditing, isCoffeeShop }: ProductFormProps) {
    const [name, setName] = useState("");
    const [selectedCategory, setSelectedCategory] = useState<SelectedCategory | null>(null);
    const [salePrice, setSalePrice] = useState("");
    const [departmentId, setDepartmentId] = useState<number | undefined>(isCoffeeShop ? coffeeShopDepartmentId : undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cookies] = useCookies(['session_token']);
    const { categories, loadingCategories } = useCategories();
    const { departments, loadingDepartments } = useDepartments();
    const hasAnyError = !name || !salePrice || !selectedCategory;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
    const handleSalePriceChange = (value: string) => {
        const fixed = (parseFloat(value) / 100).toFixed(2);
        setSalePrice(fixed);
    };
    const handleCategoryChange = (value: string | SelectedCategory | null) => {
        if (typeof value === "string") setSelectedCategory({ name: value });
        else if (value && value.inputValue) setSelectedCategory({ name: value.inputValue });
        else setSelectedCategory(value);
    };
    const handleDepartmentSelection = (e: SelectChangeEvent<number>) => setDepartmentId(Number(e.target.value));
    const filterCategoryOptions = (options: SelectedCategory[], params: FilterOptionsState<SelectedCategory>) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const exists = options.some((o) => inputValue === o.name);
        if (inputValue && !exists) filtered.push({ inputValue, name: `Criar "${inputValue}"` });
        return filtered;
    };
    const getOptionLabel = (option: SelectedCategory | string) => {
        if (typeof option === 'string') return option;
        else if (option.inputValue) return option.inputValue;
        return option.name;
    };
    const formatPrice = (value: string) => {
        if (!value) return '';
        const formatted = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2
        }).format(parseFloat(value) / 100);
        return formatted;
    };
    const renderSelectedDepartment = (id?: number) => {
        if (id && departmentId) return (
            <span style={{ display: "flex", gap: "1.5rem" }}>
                <Business />
                {departments?.find((d) => d.Id === Number(id))?.Name}
            </span>
        );
        else return null;
    };
    const makePatchData = () => {
        const patchData: Partial<Product> = {};
        if (name !== product?.Name) patchData.Name = name;
        if (selectedCategory?.name !== product?.Category?.Name) patchData.Category = { Name: selectedCategory?.name };
        if (Number(salePrice) !== Number(product?.SalePrice)) patchData.SalePrice = Number(salePrice);
        return patchData;
    };
    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        let isValid = true;
        if (!name) isValid = false;
        if (!selectedCategory) isValid = false;
        if (!salePrice) isValid = false;

        if (isValid) {
            const data: Partial<Product> = {
                Name: name,
                SalePrice: Number(salePrice),
                DepartmentId: departmentId,
            };
            const existingCategory = categories?.find((c) => c.Name === selectedCategory?.name);
            if (existingCategory) data.CategoryId = existingCategory.Id;
            else data.Category = { Name: selectedCategory?.name };

            if (isEditing && product) {
                const patchData = makePatchData();
                if (!Object.entries(patchData).length) {
                    InfoToast("Nada mudou! Antes de salvar, por favor altere algo!");
                    setIsSubmitting(false);
                }
                else {
                    ProductService.UpdateProduct(product.Id, patchData, cookies.session_token)
                    .then(() => {
                        onClose?.(true);
                    })
                    .catch((err) => {
                        if (err?.response?.status === 404) {
                            InfoToast('O item não estocável que você está tentando atualizar não existe! Por favor atualize a página!');
                        }
                        else {
                            ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                        }
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
                }
            }
            else {
                ProductService.CreateProduct(cookies.session_token, data)
                .then(() => {
                    onClose?.(true);
                })
                .catch((err) => {
                    if (err?.response?.status === 409) {
                        ErrorToast('Já existe um item não estocável com esse nome!');
                    }
                    else {
                        ErrorToast('Ocorreu um erro inesperado, por favor, tente novamente!');
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                })
            }
        }
    };

    useEffect(() => {
        if (isEditing && product) {
            setName(product.Name);
            setSalePrice(Number(product.SalePrice).toFixed(2));
            setDepartmentId(product.DepartmentId);
            if (product.Category?.Name) setSelectedCategory({ name: product.Category?.Name });
        }
    }, [isEditing, product]);

    return (
        <div className="product-form">
            <div className="basic-info">
                <h5>Informações básicas</h5>
                <FormControl>
                    <TextField
                        label="Nome"
                        value={name}
                        onChange={handleNameChange}
                        inputProps={{ maxLength: PRODUCT_NAME_MAX_LENGTH }}
                    />
                    {name.length === PRODUCT_NAME_MAX_LENGTH && <FormHelperText>O nome pode só ter até no máximo {PRODUCT_NAME_MAX_LENGTH} caracteres</FormHelperText>}
                </FormControl>
                <FormControl>
                    <NumberFormatBase
                        value={salePrice}
                        format={formatPrice}
                        onValueChange={(values) => handleSalePriceChange(values.value)}
                        customInput={TextField}
                        label="Preço para venda"
                        placeholder="R$ 0,00"
                    />
                </FormControl>
            </div>
            <div className="product-category">
                <h5>Categoria</h5>
                <FormControl>
                    <Autocomplete
                        value={selectedCategory}
                        onChange={(e, value) => handleCategoryChange(value)}
                        filterOptions={filterCategoryOptions}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={categories?.map((c) => ({ name: c.Name, } as SelectedCategory))!}
                        getOptionLabel={getOptionLabel}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => <TextField {...params} variant="outlined" color="primary" label="Categoria" />}
                        disabled={loadingCategories}
                    />
                </FormControl>
            </div>
            <FormControl>
                <InputLabel id="department" required disabled={loadingDepartments || isCoffeeShop}>Departamento</InputLabel>
                <Select
                    labelId="department"
                    id="Department"
                    label="Departamento"
                    value={isEditing && product ? product.DepartmentId : departmentId}
                    renderValue={renderSelectedDepartment}
                    onChange={handleDepartmentSelection}
                    disabled={loadingDepartments || isCoffeeShop}
                >
                    {departments?.map((d, i) => (
                        <MenuItem
                            key={i}
                            value={d.Id}
                            sx={{ display: "flex", gap: "1.5rem" }}
                        >
                            <Business />
                            {d.Name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={hasAnyError}
            >
                {isSubmitting ?
                    <CircularProgress color="inherit" size={24} />
                    :
                    (isEditing ? 'Salvar': 'Criar')
                }
            </Button>
        </div>
    );
}
