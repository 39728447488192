import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";


type AODataTableProps = {
    columns: GridColDef[];
    isLoading?: boolean;
    preComponent?: React.JSX.Element | string;
    rows: any[];
    title?: string;
    preComponentAction?: React.JSX.Element;
    useDefaultPreComponent?: boolean;
    rowIdProp?: string;
};

export default function AODataTable({
    columns,
    isLoading,
    rows,
    preComponent,
    preComponentAction,
    title,
    useDefaultPreComponent,
    rowIdProp
}: AODataTableProps) {
    return (
        <div style={{ textAlign: "center" }}>
            <Grid
                container
                sx={{ marginTop: "4rem" }}
            >
                {useDefaultPreComponent ?
                    <>
                        <Grid item xs={6} sx={{ color: "white" }}>
                            <h3>{title}</h3>
                        </Grid>
                        <Grid item xs={6}>
                            {preComponentAction}
                        </Grid>
                    </>
                    :
                    preComponent
                }
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                    {isLoading ?
                        <Skeleton variant="rounded" width={780} height={400} sx={{ m: 2, }} />
                        :
                        <DataGrid
                            autoHeight
                            disableRowSelectionOnClick
                            localeText={{
                                noRowsLabel: 'Nada para mostrar'
                            }}
                            columns={columns}
                            rows={rows}
                            getRowId={(row) => rowIdProp ? row?.[rowIdProp] : row?.Id}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 25 },
                                },
                            }}
                            sx={{
                                backgroundColor: "white",
                                width: "100%",
                                m: 2,
                            }}
                        />
                    }
                </Grid>
            </Grid>
        </div>
    );
}
