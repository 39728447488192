import { WrappedUser } from "../types/user.types";


const UserWrapper = (() => {
    const defaultUser: WrappedUser = {
        session: { auth: false },
    };

    const get = (): WrappedUser => {
        const user = window.sessionStorage.getItem('user');
        if (user && user !== "undefined") return JSON.parse(user);
        else return defaultUser;
    };

    const set = (user: WrappedUser) => {
        window.sessionStorage.setItem('user', JSON.stringify(user));
    };

    const clear = () => window.sessionStorage.removeItem('user');

    return {
        clear,
        get,
        set,
    };
})();

export default UserWrapper;
