import React, { useState } from "react";
import { Button, Grid, IconButton, Skeleton, TextField, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet";
import { useQueryClient } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AddBox, DeleteForever as DeleteIcon, Edit as EditIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import UserWrapper from "../../wrappers/user.wrapper";
import { useDepartments, useStocks } from "../../hooks";
import { CheckIfUserHasPermission } from "../../utils/access";
import {
    CREATE_STOCK_ALLOWED_ACCESSES,
    DELETE_STOCK_ALLOWED_ACCESSES,
    EDIT_STOCK_ALLOWED_ACCESSES,
    LIST_STOCKS_ALLOWED_ACCESSES
} from "../../constants/access.constant";
import GenericDialog from "../../components/Dialogs/Generic";
import { Stock } from "../../types/stock.types";
import { PromiseToast, SuccessToast } from "../../utils/toaster";
import StockForm from "../../components/Forms/StockForm";
import * as StockService from "../../services/stock.service";
import "./Stocks.styles.scss";


export default function Stocks() {
    const [showStockFormDialog, setShowStockFormDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [stockToEdit, setStockToEdit] = useState<Stock>();
    const [stockToDelete, setStockToDelete] = useState<Stock>();
    const [isDeleteInputTxtValid, setIsDeleteInputTxtValid] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [cookies] = useCookies(["session_token"]);
    const user = UserWrapper.get();
    const { stocks, loadingStocks } = useStocks();
    const { departments, loadingDepartments } = useDepartments();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const isUserAllowedToCreate = CheckIfUserHasPermission(
        user.access!,
        CREATE_STOCK_ALLOWED_ACCESSES
    );
    const isUserAllowedToEdit = (stockId: number) => CheckIfUserHasPermission(
        user.access!,
        EDIT_STOCK_ALLOWED_ACCESSES(stockId)
    );
    const isUserAllowedToDelete = (stockId: number) => CheckIfUserHasPermission(
        user.access!,
        DELETE_STOCK_ALLOWED_ACCESSES(stockId),
    );
    const isUserAllowedToView = CheckIfUserHasPermission(
        user.access!,
        LIST_STOCKS_ALLOWED_ACCESSES
    );

    const columns: GridColDef<Stock>[] = [
        { field: "Name", headerName: "Nome", minWidth: 200, maxWidth: 250, align: "center", headerAlign: "center" },
        {
            field: "DepartmentId",
            headerName: "Departamento",
            width: 120,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => departments?.find((d) => d.Id === params.value)?.Name,
        },
        { field: "CreatedDate", headerName: "Data de Criação", width: 200, align: "center", headerAlign: "center", valueFormatter: (params) => new Date(params.value).toLocaleString() },
        { field: "UpdatedDate", headerName: "Última Atualização", width: 200, align: "center", headerAlign: "center", valueFormatter: (params) => params.value ? new Date(params.value).toLocaleString() : '-' },
        {
            field: "Actions",
            headerName: "Ações",
            width: 150,
            align: "center",
            headerAlign: "center",
            colSpan: 4,
            renderCell: (params) => (
                <span>
                    <Tooltip
                        title={!isUserAllowedToView ? "Você não tem permissão pra fazer isso" : "Ver detalhes"}
                        arrow
                    >
                        <span>
                            <IconButton
                                disabled={!isUserAllowedToView}
                                onClick={(e) => handleViewButtonClick(params.row.Id)}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={!isUserAllowedToEdit(params.row.Id) ? "Você não tem permissão pra fazer isso" : "Editar"}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToEdit(params.row.Id)} onClick={(e) => handleEditButtonClick(params.row)}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={!isUserAllowedToDelete(params.row.Id) ? "Você não tem permissão pra fazer isso" : "Deletar"}
                        arrow
                    >
                        <span>
                            <IconButton disabled={!isUserAllowedToDelete(params.row.Id)} onClick={(e) => { toggleDeleteDialog(params.row) }}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </span>
            ),
        },
    ];

    const toggleStockFormDialog = (submitted?: boolean) => {
        if (typeof submitted === "boolean" && submitted) {
            queryClient.invalidateQueries(['stocks']).then(() => {
                queryClient.invalidateQueries(['departments']).then(() => {
                    SuccessToast(`Estoque ${isEditing ? 'atualizado' : 'criado'}!`);
                });
            });
        }
        setShowStockFormDialog(!showStockFormDialog);
    };
    const toggleDeleteDialog = (clickedStock?: Stock) => {
        if (clickedStock) setStockToDelete(clickedStock);
        setShowDeleteDialog(!showDeleteDialog);
    };
    const handleCreateButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setIsEditing(false);
        toggleStockFormDialog();
    };
    const handleViewButtonClick = (stockId: number) => navigate(`/stocks/${stockId}`);
    const handleEditButtonClick = (s: Stock) => {
        setIsEditing(true);
        setStockToEdit(s);
        toggleStockFormDialog();
    };
    const deleteStock = async () => {
        if (stockToDelete) {
            toggleDeleteDialog();
            await PromiseToast(
                StockService.DeleteStock(stockToDelete.Id, cookies.session_token),
                {
                    error: "Não conseguimos deletar o estoque. Por favor tente novamente!",
                    pending: "Deletando estoque...",
                    success: "Estoque deletado!",
                }
            );
            await queryClient.invalidateQueries(['stocks']);
        }
    };
    const validateDeleteInputTxt = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsDeleteInputTxtValid(e.target.value === stockToDelete?.Name);
    };

    return (
        <>
            <Helmet title="Admin ONE | Estoques" />
            <GenericDialog
                onClose={toggleDeleteDialog}
                open={showDeleteDialog}
                title="Deletar Estoque"
                useConfirmAndCancelButtons
                onConfirm={deleteStock}
                disableConfirm={!isDeleteInputTxtValid}
            >
                <span style={{ lineHeight: "3rem" }}>
                    <p style={{ fontSize: "1.2rem" }}>Tem certeza que deseja deletar esse estoque?</p>
                    <p style={{ fontSize: "1.2rem" }}>Para confirmar digite <strong>{stockToDelete?.Name}</strong> no campo abaixo:</p>
                    <TextField fullWidth onChange={validateDeleteInputTxt} size="small" />
                </span>
            </GenericDialog>
            <GenericDialog
                onClose={toggleStockFormDialog}
                open={showStockFormDialog}
                title={`${isEditing ? 'Editar' : 'Criar'} Estoque`}
            >
                <StockForm
                    stock={stockToEdit}
                    isEditing={isEditing}
                    onClose={toggleStockFormDialog}
                    departments={departments}
                />
            </GenericDialog>
            <div style={{ textAlign: "center" }}>
                <Grid
                    container
                    sx={{ marginTop: "4rem" }}
                >
                    <Grid item xs={6} sx={{ color: "white" }}>
                        <h3>Lista de Estoques</h3>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            title={!isUserAllowedToCreate && "Você não tem permissão pra fazer isso"}
                            disableHoverListener={isUserAllowedToCreate}
                            arrow
                        >
                            <span>
                                <Button
                                    startIcon={<AddBox />}
                                    variant="contained"
                                    sx={{ minWidth: "14rem", maxWidth: "16rem" }}
                                    disabled={!isUserAllowedToCreate || loadingStocks}
                                    classes={{ disabled: "create-stock-btn-disabled" }}
                                    onClick={handleCreateButtonClick}
                                >
                                    Criar Estoque
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        {loadingStocks || loadingDepartments ?
                            <Skeleton variant="rounded" width={780} height={400} sx={{ m: 2, }} />
                            :
                            <DataGrid
                                autoHeight
                                disableRowSelectionOnClick
                                localeText={{
                                    noRowsLabel: "Nada para mostrar",
                                }}
                                columns={columns}
                                rows={stocks!}
                                getRowId={(row) => row?.Id}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 25 },
                                    },
                                }}
                                sx={{
                                    backgroundColor: "white",
                                    width: "100%",
                                    m: 2,
                                }}
                            />
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
