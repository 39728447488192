import { User } from "../../types/user.types"


type LoggedHomeProps = {
    user: User;
}

export default function LoggedHome({ user }: LoggedHomeProps) {
    return (
        <>
            <h2>Bem vindo(a) {user?.FirstName}!</h2>
            <h4>Essa é a plataforma de administração da nossa igreja!</h4>
        </>
    );
}
