import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Grid, Tab, Tabs } from "@mui/material";

import { useDepartment } from "../../hooks";


export default function DetailedDepartment() {
    const [currentTab, setCurrentTab] = useState(0);
    const { id } = useParams();
    const { department, loadingDepartment } = useDepartment(Number(id));

    const handleTabChange = (e: React.SyntheticEvent, newTabIndex: number) => setCurrentTab(newTabIndex);

    return (
        <>
            <Helmet title="Admin ONE | " />
            {loadingDepartment ?
                <Grid container>
                    <CircularProgress />
                </Grid>
                :
                <Box sx={{ width: "100%" }}>
                    <Box>
                        <Tabs value={currentTab} onChange={handleTabChange}>
                            <Tab label="Overview" />
                            <Tab label="Membros" />
                            <Tab label="Estoques" />
                        </Tabs>
                    </Box>
                    <div hidden={currentTab !== 0} style={{ width: "100%", textAlign: "center", color: "white" }}>
                        <h3>{department?.Name}</h3>
                    </div>
                </Box>
            }
        </>
    )
}
