export const APP_ID = 1;

export const BRANCHES = {
    RJ: 1,
};

export const PURCHASE_TYPES = {
    Profit: 1,
    Internal: 2
};

export const PRODUCT_NAME_MAX_LENGTH = 60;
