import React from "react";
import Helmet from "react-helmet";
import { Navigate } from "react-router-dom";

import UserWrapper from "../../wrappers/user.wrapper";
import LoggedHome from "./Logged";
import AnonymousHome from "./Anonymous";
import "./styles.scss";


export default function Home() {
    const user = UserWrapper.get();

    return (
        <>
            <Helmet title="Home | Admin ONE" />
            <div className="home-content">
                {user.session.auth ?
                    (
                        user.isCoffeeShopStaff ?
                            <Navigate to="/coffee-shop" />
                            :
                            <LoggedHome user={user?.data!} />
                    )
                    :
                    <AnonymousHome />
                }
            </div>
        </>
    );
}
