import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CircularProgress, CssBaseline } from "@mui/material";

import Header from "./Header";
import AppRoutes from "../../routes";
import { google } from "../../config";
import { useLoggedUser, useSession } from "../../hooks";
import { defaultTheme } from "../../themes";


export default function Layout() {
    const { loadingSession, session } = useSession();
    const { loadingUser, user } = useLoggedUser();
    const loading = loadingSession || loadingUser;

    useEffect(() => {}, [user, session]);

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <GoogleOAuthProvider clientId={google.clientId!}>
                    <Router>
                        <ToastContainer />
                        {loading ?
                            <CircularProgress sx={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }} />
                            :
                            <>
                                <Header />
                                <AppRoutes />
                            </>
                        }
                    </Router>
                </GoogleOAuthProvider>
            </ThemeProvider>
        </>
    );
}
