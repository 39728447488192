import { Product } from "../types/product.type";
import { WrappedCashier, WrappedSale } from "../types/sales.type";


const CashierWrapper = (() => {
    const defaultCashier: WrappedCashier = {
        Products: [],
        Sales: [],
    };

    const get = (): WrappedCashier => {
        const cashier = window.localStorage.getItem('cashier');
        if (cashier && cashier !== "undefined") return JSON.parse(cashier);
        else return defaultCashier;
    };

    const set = (cashier: WrappedCashier) => {
        window.localStorage.setItem('cashier', JSON.stringify(cashier));
    };

    const addProduct = (product: Product) => {
        const cashier = get();
        const products = [...cashier.Products];
        products.push(product);
        cashier.Products = products;
        set(cashier);
    };

    const removeProduct = (id: number) => {
        const cashier = get();
        const filtered = cashier.Products.filter((p) => p.Id !== id);
        cashier.Products = filtered;
        set(cashier);
    };

    const addSale = (sale: WrappedSale) => {
        const cashier = get();
        const sales = [...cashier.Sales];
        sales.push(sale);
        cashier.Sales = sales;
        set(cashier);
    };

    const removeSale = (sale: WrappedSale) => {
        const cashier = get();
        const sales = [...cashier.Sales];
        cashier.Sales = sales.filter((s) => s.uuid !== sale.uuid);
        set(cashier);
    };

    const updateSale = (sale: WrappedSale) => {
        removeSale(sale);
        addSale(sale);
    };

    const clear = () => window.localStorage.removeItem('cashier');

    return {
        addProduct,
        addSale,
        clear,
        get,
        removeProduct,
        removeSale,
        set,
        updateSale,
    };
})();

export default CashierWrapper;
