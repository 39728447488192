import { ToastContent, ToastOptions, ToastPromiseParams, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const defaultOptions: ToastOptions = {
    style: {
        fontWeight: 'bold',
        fontSize: '1rem',
    },
    position: toast.POSITION.TOP_CENTER,
    closeOnClick: true,
};

export function SuccessToast(content: ToastContent, options?: ToastOptions) {
    return toast.success(content, { ...defaultOptions, ...options });
}

export function ErrorToast(content: ToastContent, options?: ToastOptions) {
    return toast.error(content, { ...defaultOptions, ...options });
}

export function WarningToast(content: ToastContent, options?: ToastOptions) {
    return toast.warning(content, { ...defaultOptions, ...options });
}

export function InfoToast(content: ToastContent, options?: ToastOptions) {
    return toast.info(content, { ...defaultOptions, ...options });
}

export function PromiseToast(promiseFn: Promise<any> | (() => Promise<any>), params: ToastPromiseParams, options?: ToastOptions) {
    return toast.promise(promiseFn, params, { ...defaultOptions, ...options });
}
