import React, { useState } from "react";
import { Button, IconButton, Tooltip, } from "@mui/material";
import { Helmet } from "react-helmet";
import {
    AddBox,
    Cancel as NotOkayIcon,
    CheckCircle as OkayIcon,
    DeleteForever as DeleteIcon,
    Edit as EditIcon,
    ShoppingCart as ShoppingCartIcon,
} from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { useCookies } from "react-cookie";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import ItemPurchaseForm from "../../components/Forms/ItemPurchaseForm";
import GenericDialog from "../../components/Dialogs/Generic";
import AODataTable from "../../components/Table/DataTable";
import PurchaseWrapper from "../../wrappers/purchase.wrapper";
import { useCategories, usePurchaseTypes, useStocks } from "../../hooks";
import { calculateNetCost, friendlyPurchaseType } from "../../utils/purchase";
import { Category, WrappedItem } from "../../types/item.types";
import { PromiseToast, SuccessToast } from "../../utils/toaster";
import * as PurchaseService from "../../services/purchase.service";
import UserWrapper from "../../wrappers/user.wrapper";
import "./NewPurchase.styles.scss";


export default function NewPurchase() {
    const [itemToEdit, setItemToEdit] = useState<WrappedItem>();
    const [isEditing, setIsEditing] = useState(false);
    const [showItemPurchaseDialog, setShowItemPurchaseDialog] = useState(false);
    const [showFinishPurchaseDialog, setShowFinishPurchaseDialog] = useState(false);
    const purchase = PurchaseWrapper.get();
    const user = UserWrapper.get();
    const { purchaseTypes, loadingPurchaseTypes } = usePurchaseTypes();
    const { stocks, loadingStocks } = useStocks();
    const { categories, loadingCategories } = useCategories();
    const [cookies] = useCookies(['session_token']);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const toggleItemPurchaseDialog = () => setShowItemPurchaseDialog(!showItemPurchaseDialog);
    const toggleFinishPurchaseDialog = () => setShowFinishPurchaseDialog(!showFinishPurchaseDialog);
    const handleEditButtonClick = (item: WrappedItem) => {
        setIsEditing(true);
        setItemToEdit(item);
        toggleItemPurchaseDialog();
    };
    const handleAddItemButtonClick = () => {
        setIsEditing(false);
        toggleItemPurchaseDialog();
    };
    const handleRemoveItemButtonClick = (uuid: string) => {
        PurchaseWrapper.removeItem(uuid);
        setIsEditing(!isEditing); // this is just to force a re-render
        SuccessToast("Ítem removido!");
    };
    const finishPurchase = async () => {
        toggleFinishPurchaseDialog();
        await PromiseToast(
            PurchaseService.CreatePurchase({ Items: purchase.Items, UserId: user.data?.Id! }, cookies.session_token),
            {
                error: "Não foi possível finalizar a compra! Por favor revise os ítens e tente novamente!",
                pending: "Finalizando a compra...",
                success: "Compra finalizada!",
            }
        );
        PurchaseWrapper.clear();
        await queryClient.invalidateQueries(["purchases"]);
        navigate("/purchases");
    };
    const purchaseGrossCost = purchase.Items.reduce((acc, curr) => acc + curr.BuyingPrice, 0);
    const purchaseNetCost = purchase.Items.reduce((acc, curr) => acc + calculateNetCost(curr.BuyingPrice, curr.Amount), 0);
    const profitItemsLength = purchase.Items.filter((i) => i.Purchase?.PurchaseTypeId === 1).length;
    const internalCostItemsLength = purchase.Items.filter((i) => i.Purchase?.PurchaseTypeId === 2).length;

    const columns: GridColDef[] = [
        { field: "Name", headerName: "Nome", width: 250, align: "center", headerAlign: "center", },
        { field: "Amount", headerName: "Quantidade", width: 100, align: "center", headerAlign: "center", },
        {
            field: "PurchaseType",
            headerName: "Tipo",
            width: 100,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => friendlyPurchaseType(purchaseTypes?.find((pt) => pt.Id === params.row.Purchase.PurchaseTypeId)?.Name!)?.replace(/\(([^)]+)\)/, ""),
        },
        {
            field: "Stock",
            headerName: "Estoque",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => stocks?.find((s) => s.Id === params.row.StockId)?.Name,
        },
        {
            field: "isStockReposition",
            headerName: "Reposição?",
            width: 100,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row.isStockReposition ? <OkayIcon /> : <NotOkayIcon />,
        },
        {
            field: "Category",
            headerName: "Categoria",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.row?.Category?.Name || categories?.find((c: Category) => c.Id === params.row?.CategoryId)?.Name,
        },
        {
            field: "CostVariablePercentage",
            headerName: "Custo Variável (%)",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `${params.row.Purchase.CostVariablePercentage}%`,
        },
        {
            field: "TotalCost",
            headerName: "Total (R$)",
            width: 100,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => `R$${params.row.Purchase.TotalCost.toFixed(2)}`,
        },
        {
            field: "Actions",
            headerName: "Ações",
            width: 150,
            maxWidth: 300,
            align: "center",
            headerAlign: "center",
            colSpan: 4,
            renderCell: (params) => (
                <span>
                    <Tooltip
                        title="Editar ítem"
                        arrow
                    >
                            <IconButton onClick={(e) => handleEditButtonClick(params.row)}>
                                <EditIcon />
                            </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Remover ítem"
                        arrow
                    >
                            <IconButton onClick={(e) => handleRemoveItemButtonClick(params.row.uuid)}>
                                <DeleteIcon />
                            </IconButton>
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <>
            <Helmet title="Admin ONE | Nova Compra" />
            <GenericDialog
                onClose={toggleItemPurchaseDialog}
                open={showItemPurchaseDialog}
                title={`${isEditing ? 'Editar' : 'Novo'} Ítem`}
            >
                <ItemPurchaseForm
                    onClose={toggleItemPurchaseDialog}
                    isEditing={isEditing}
                    purchaseItem={itemToEdit}
                />
            </GenericDialog>
            <GenericDialog
                onClose={toggleFinishPurchaseDialog}
                open={showFinishPurchaseDialog}
                title="Finalizar Compra"
                useConfirmAndCancelButtons
                onConfirm={finishPurchase}
            >
                <span style={{ lineHeight: "3rem" }}>
                    <p style={{ fontSize: "1.2rem" }}>Resumo:</p>
                    <span style={{ lineHeight: "1.8rem" }}>
                        <p>Quantidade total de ítens: <strong>{purchase.Items.length}</strong> 📊</p>
                        <p><strong>{profitItemsLength}</strong> {profitItemsLength > 1 ? 'ítens vão' : 'ítem vai'} gerar lucro! 💰</p>
                        <p><strong>{internalCostItemsLength}</strong> ítens vão apenas gerar custo! 💸</p>
                        <p>Custo bruto: <strong>R${purchaseGrossCost.toFixed(2)} 💸 💪</strong></p>
                        <p>Custo líquido: <strong>R${purchaseNetCost.toFixed(2)} 💸 💧</strong></p>
                    </span>
                    <p style={{ fontSize: "1.2rem" }}>Tem certeza que deseja finalizar essa compra agora?</p>
                </span>
            </GenericDialog>
            <AODataTable
                columns={columns}
                rows={purchase.Items}
                useDefaultPreComponent
                rowIdProp="Name"
                title="Ítens da Nova Compra"
                isLoading={loadingPurchaseTypes || loadingStocks || loadingCategories}
                preComponentAction={
                    <span className="np-data-table-actions">
                        <Button
                            startIcon={<AddBox />}
                            variant="contained"
                            sx={{ minWidth: "14rem", maxWidth: "16rem" }}
                            onClick={handleAddItemButtonClick}
                        >
                            Adicionar Ítem
                        </Button>
                        <Button
                            disabled={!purchase.Items.length}
                            startIcon={<ShoppingCartIcon />}
                            variant="contained"
                            onClick={toggleFinishPurchaseDialog}
                            color="success"
                            classes={{ disabled: "finish-purchase-button-disabled" }}
                        >
                            Finalizar
                        </Button>
                    </span>
                }
            />
        </>
    );
}
