import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Users from "../pages/Users";
import UserWrapper from "../wrappers/user.wrapper";
import { PageConfig } from "../types/route.types";
import {
    ALL_SYSTEM_ACCESSES,
    COFFEE_SHOP_VIEW_ALLOWED_ACCESSES,
    CREATE_PURCHASE_ALLOWED_ACCESSES,
    LIST_DEPARTMENTS_ALLOWED_ACCESSES,
    LIST_PURCHASES_ALLOWED_ACCESSES,
    LIST_SALES_ALLOWED_ACCESSES,
    LIST_STOCKS_ALLOWED_ACCESSES,
    LIST_USERS_ALLOWED_ACCESSES,
} from "../constants/access.constant";
import { CheckIfUserHasPermission } from "../utils/access";
import Departments from "../pages/Departments";
import DetailedDepartment from "../pages/Departments/DetailedDepartment";
import Stocks from "../pages/Stocks";
import Purchases from "../pages/Purchases";
import NewPurchase from "../pages/NewPurchase";
import DetailedPurchase from "../pages/DetailedPurchase";
import DetailedStock from "../pages/DetailedStock";
import Sales from "../pages/Sales";
import CoffeeShop from "../pages/CoffeeShop";
import Products from "../pages/Products";
import CoffeeShopStaff from "../pages/CoffeeShop/Staff";
import CoffeeShopCashier from "../pages/CoffeeShop/Cashier";
import DetailedSale from "../pages/DetailedSale";


export const Pages: PageConfig[] = [
    { path: "/", element: <Home />, name: "Home", requireLogin: false, headerLink: true, },
    {
        path: "/users",
        element: <Users />,
        name: "Usuários",
        requireLogin: true,
        allowedAccesses: LIST_USERS_ALLOWED_ACCESSES,
        headerLink: true,
    },
    {
        path: "/departments",
        element: <Departments />,
        name: "Departamentos",
        requireLogin: true,
        allowedAccesses: LIST_DEPARTMENTS_ALLOWED_ACCESSES,
        headerLink: true,
    },
    {
        path: "/departments/:id",
        element: <DetailedDepartment />,
        name: "Departamento Detalhado",
        requireLogin: true,
    },
    {
        path: "/stocks",
        element: <Stocks />,
        name: "Estoques",
        requireLogin: true,
        headerLink: true,
        allowedAccesses: LIST_STOCKS_ALLOWED_ACCESSES,
    },
    {
        path: "/stocks/:id",
        element: <DetailedStock />,
        name: "Estoques",
        requireLogin: true,
        headerLink: false,
    },
    {
        path: "/purchases",
        element: <Purchases />,
        name: "Compras",
        requireLogin: true,
        headerLink: true,
        allowedAccesses: LIST_PURCHASES_ALLOWED_ACCESSES,
    },
    {
        path: "/purchases/create",
        element: <NewPurchase />,
        name: "Nova Compra",
        requireLogin: true,
        headerLink: false,
        allowedAccesses: CREATE_PURCHASE_ALLOWED_ACCESSES,
    },
    {
        path: "/purchases/:id",
        element: <DetailedPurchase />,
        name: "Compra Detalhada",
        requireLogin: true,
        headerLink: false,
    },
    {
        path: "/sales",
        element: <Sales />,
        name: "Vendas",
        requireLogin: true,
        headerLink: true,
        allowedAccesses: LIST_SALES_ALLOWED_ACCESSES,
    },
    {
        path: "/sales/:id",
        element: <DetailedSale />,
        name: "Venda Detalhada",
        requireLogin: true,
        headerLink: false,
        allowedAccesses: LIST_SALES_ALLOWED_ACCESSES,
    },
    {
        path: "/products",
        element: <Products />,
        name: "Produtos",
        requireLogin: true,
        headerLink: true,
        allowedAccesses: ALL_SYSTEM_ACCESSES,
    },
    {
        path: "/coffee-shop",
        element: <CoffeeShop />,
        name: "Coffee Shop",
        requireLogin: true,
        headerLink: true,
        allowedAccesses: COFFEE_SHOP_VIEW_ALLOWED_ACCESSES,
    },
    {
        path: "/coffee-shop/products",
        element: <Products />,
        name: "Coffee Shop - Produtos",
        requireLogin: true,
        headerLink: false,
        allowedAccesses: COFFEE_SHOP_VIEW_ALLOWED_ACCESSES,
    },
    {
        path: "/coffee-shop/staff",
        element: <CoffeeShopStaff />,
        name: "Coffee Shop Staff",
        requireLogin: true,
        headerLink: false,
        allowedAccesses: COFFEE_SHOP_VIEW_ALLOWED_ACCESSES,
    },
    {
        path: "/coffee-shop/cashier",
        element: <CoffeeShopCashier />,
        name: "Coffee Shop - Caixa",
        requireLogin: true,
        headerLink: false,
        allowedAccesses: COFFEE_SHOP_VIEW_ALLOWED_ACCESSES,
    },
    {
        path: "/coffee-shop/sales",
        element: <Sales />,
        name: "Coffee Shop - Vendas",
        requireLogin: true,
        headerLink: false,
        allowedAccesses: COFFEE_SHOP_VIEW_ALLOWED_ACCESSES,
    },
    {
        path: "/coffee-shop/sales/:id",
        element: <DetailedSale />,
        name: "Coffee Shop - Venda Detalhada",
        requireLogin: true,
        headerLink: false,
        allowedAccesses: COFFEE_SHOP_VIEW_ALLOWED_ACCESSES,
    },
];

export default function AppRoutes() {
    const user = UserWrapper.get();

    return (
        <Routes>
            {Pages.map((page, i) => (
                <Route
                    key={i}
                    path={page.path}
                    element={
                        (page.requireLogin && !user.session.auth) || !CheckIfUserHasPermission(user.access!, page.allowedAccesses) ?
                            <Navigate to="/" replace />
                            :
                            page.element
                    }
                />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
}
